import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import classnames from 'classnames';
import _ from 'lodash';

const Presets = ({ startDate, endDate, text = '', months = null, onClick }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    isExactDate();
  }, [startDate, endDate]);

  const isExactDate = () => {
    if (months) {
      if (
        moment(startDate).isSame(moment().startOf('month').subtract(months, 'months'), 'day') &&
        moment(endDate).isSame(moment().subtract(1, 'months').endOf('month'), 'day')
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else {
      if (
        moment(startDate).isSame(moment().startOf('month'), 'day') &&
        moment(endDate).isSame(moment().endOf('month'), 'day')
      ) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  };

  const handleOnClick = () => {
    if (months) {
      onClick(
        moment().startOf('month').subtract(months, 'months'),
        moment().subtract(1, 'months').endOf('month')
      );
    } else {
      onClick(moment().startOf('month'), moment().endOf('month'));
    }
  };

  return (
    <button
      type="button"
      className={classnames('DefaultDatePicker-Shortcuts__Button', {
        ['DefaultDatePicker-Shortcuts__Button--active']: isActive,
      })}
      onClick={() => handleOnClick()}
      data-fx-name={_.camelCase(text)}
    >
      {text}
    </button>
  );
};

export default Presets;
