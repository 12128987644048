import React from 'react';
import PropTypes from 'prop-types';

import { api } from '@webfx/core-web';

const Variant = ({ id, variations }) => {
  const storageKey = `Variant/${id}`;
  const [variation, setVariation] = React.useState(() =>
    window.localStorage.getItem(storageKey ?? null)
  );

  api.useQuery(['etc/variations', id, { variations: variations.length }], {
    enabled: !variation && !!id,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      window.localStorage.setItem(storageKey, data.variation);
      setVariation(data.variation);
    },
  });

  if (!variation) {
    return null;
  }
  return variations[variation - 1];
};

Variant.propTypes = {
  id: PropTypes.string.isRequired,
  variations: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Variant;
