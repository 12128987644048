import useCompanyUsers from '@webfx/permissions-web/src/hooks/useCompanyUsers';
import { useNotifications } from '@webfx/web-hooks';

const roles = [
  {
    name: 'Primary Contact',
    key: 'primaryContact',
  },
  {
    name: 'Executive Contact',
    key: 'decisionMaker',
  },
  {
    name: 'External Contact',
    key: 'externalContact',
  },
  {
    name: 'Interactive Contact',
    key: 'interactiveContact',
  },
  {
    name: 'Billing Contact',
    key: 'billingContact',
  },
];

/**
 * @param {object} props
 * @param {object} props.userId
 * @param {object} props.companyId
 * @returns {object}
 */
export default function useUserRoles({ userId, companyId } = {}) {
  const { data: [companyUser] = [{}], mutator: companiesUsersMutator } = useCompanyUsers(
    companyId,
    {
      enabled: !!(userId && companyId),
    },
    { userId, $limit: -1, $join: ['user', 'company'] }
  );
  const { toast } = useNotifications();

  function onMutate(prop, _companyUser) {
    const {
      companyUserId,
      [prop]: value,
      userId,
      user,
      company,
      companyId,
    } = _companyUser || companyUser;
    companiesUsersMutator
      .mutateAsync(
        {
          _method: 'patch',
          _id: companyUserId,
          [prop]: !value,
          userId,
          companyId,
        },
        { userId }
      )
      .then(() => {
        const successMessage = getSuccessMessage(prop, !value, user, company.name);
        toast.success(successMessage);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  return { onMutate, companyUser, roles };
}

const getSuccessMessage = (prop, updatedValue, user, companyName) => {
  const { firstName, lastName } = user;
  const identifier =
    firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : user.email;
  const messages = {
    active: updatedValue
      ? `${identifier} has been marked as active for ${companyName} and will be able to log in to app.webfx.com`
      : `${identifier} has been marked as inactive for ${companyName} and will not be able to log in to app.webfx.com or receive app notifications.`,
  };
  roles.forEach((role) => {
    messages[role.key] = updatedValue
      ? `${identifier} is now a ${role.name} for ${companyName}.`
      : `${identifier} is no longer a ${role.name} for ${companyName}.`;
  });
  return messages[prop];
};
