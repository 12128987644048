import { api } from '@webfx/core-web';

/**
 * Generates Nutshell Authentication
 *
 * @param {string} authFor - The type of auth to generate (crm or embed)
 * @returns {*}
 */
export default function useNutshellAccount(emailAddress) {
  const account = api.useQuery([`nutshell/email`, { emailAddress }], {
    enabled: !!emailAddress,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
  });

  return account;
}
