import React, { useEffect } from 'react';
import classnames from 'classnames';

import { Popover, Transition } from '@headlessui/react';
import { Icon } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';
import { useGamePlanKanbanView } from '@webfx/gameplan-ui';

import { getCurrent, getQuarters, cutoffMonthQuarter, getCutoffYearSettingOffset } from './utils';
import styles from './QuarterSelector.module.css';

const getYearsList = (year) => {
  const years = [year ?? new Date().getFullYear()];
  years.unshift(years[0] - 1);
  years.push(years[1] + 1);
  return years;
};

/**
 * QuarterSelector component
 * @param {number} companyId - The ID of the company.
 * @param {Function} setQuarter - Callback function to set the quarter.
 * @param {object} quarter - A quarter object.
 * @param {number} quarterSetting - Index of the first month of Q1 (0-based, i.e., Jan => 0, Feb => 1, ...).
 * @param {number} year - The year to be set as selected in the quarter selector, used with quarter selector arrows.
 * @param {string} cutoff - The last month/year that should be accessible within the quarter selector in ISO format: "yyyy-mm-01".
 * @returns {Element} The rendered QuarterSelector component.
 */
export default function QuarterSelector({
  companyId,
  setQuarter,
  quarter,
  quarterSetting,
  year = null,
  cutoff,
}) {
  const flags = useFlags();
  const { data: playbookKanbanMonthViewSetting } = useGamePlanKanbanView(companyId);
  const { kanbanMonthView } = playbookKanbanMonthViewSetting;

  const isQuarterly = kanbanMonthView === 'quarterly';

  const [yearList, setYearList] = React.useState(getYearsList);
  const [selectedYear, setSelectedYear] = React.useState(new Date().getFullYear());

  const { quarters } = getQuarters(quarterSetting, selectedYear);
  const [selectedQuarter, setSelectedQuarter] = React.useState(null);

  const handleYearChange = (add) => {
    setYearList(yearList.map((year) => year + add));
  };

  const setToCurrentQuarter = () => {
    const { quarters } = getQuarters(quarterSetting);
    const { quarter, year } = getCurrent(quarters);
    setSelectedQuarter(quarter);
    setYearList(getYearsList(year));
    setSelectedYear(year);
  };

  const cutoffYearSettingOffset = getCutoffYearSettingOffset(cutoff, quarterSetting);

  useEffect(() => {
    setSelectedQuarter(quarter);
  }, [quarter]);

  useEffect(() => {
    if (year) {
      setSelectedYear(year);
      setYearList(getYearsList(year));
    }
  }, [year]);

  return (
    <Popover as="div" className="position-relative">
      <Popover.Button
        className={classnames('btn btn-outline-secondary bg-white', styles.popoverDisplay)}
        data-fx-name="quarterSelector"
      >
        {`${selectedYear} |  Q${selectedQuarter?.name ? selectedQuarter?.name?.slice(1) : ''}`}
      </Popover.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          style={{ zIndex: '1', right: 0, top: '20px' }}
          className="bg-white shadow-lg rounded-lg position-absolute mt-3"
        >
          {({ close }) => (
            <div className={classnames(['mt-1 p-3', styles.menuWrapper])}>
              <p className="font-weight-bold text-primary-dark-gray">Jump to:</p>
              <label className="d-block text-center font-weight-bold text-dark">Year:</label>

              <div className="d-flex align-items-center border-bottom pb-3">
                <button
                  onClick={() => handleYearChange(-1)}
                  className={styles.arrow}
                  disabled={yearList[0] < cutoffYearSettingOffset}
                >
                  <Icon>navigate_before</Icon>
                </button>
                <div className="d-flex align-items-center justify-content-center flex-fill">
                  {yearList.map((year, index) => (
                    <button
                      onClick={() => {
                        if (selectedYear !== year) {
                          setSelectedYear(year);
                          setSelectedQuarter(null);
                        }
                      }}
                      key={index}
                      className={classnames([
                        'btn mr-3',
                        styles.yearBadge,
                        { 'bg-blue-vivid-300 text-white': year === selectedYear },
                      ])}
                      disabled={year < cutoffYearSettingOffset}
                    >
                      {year}
                    </button>
                  ))}
                </div>
                <button onClick={() => handleYearChange(1)} className={styles.arrow}>
                  <Icon>navigate_next</Icon>
                </button>
              </div>

              <label className="d-block mt-3 text-center font-weight-bold text-dark">
                {isQuarterly || !flags.gameplanfxUpdates ? 'Quarter' : 'Starting Quarter'}
              </label>
              <div className="container m-0 pt-0 pb-3 px-3 border-bottom">
                {quarters && (
                  <div className="row">
                    {quarters?.map((quarter, index) => (
                      <button
                        key={`${quarter.label}-${index}`}
                        onClick={() => {
                          setSelectedQuarter(quarter);
                        }}
                        type="button"
                        className={classnames(styles.quarter, 'btn mt-2 col-6 pb-3', {
                          'bg-blue-vivid-300 text-white': selectedQuarter?.name === quarter?.name,
                        })}
                        disabled={
                          selectedYear === cutoffYearSettingOffset &&
                          index < cutoffMonthQuarter(cutoff, quarters)
                        }
                      >
                        <div className="d-flex flex-column justify-content-center">
                          <span className="font-14 bold">{quarter.name}</span>
                          <span
                            className={classnames('font-12 text-gray-550', {
                              'text-white': selectedQuarter?.name === quarter?.name,
                            })}
                          >
                            {quarter.label}
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center p-2">
                <button className="btn btn-sm btn-link" onClick={setToCurrentQuarter}>
                  Current Quarter
                </button>
                <button
                  onClick={() => {
                    setQuarter({ quarter: selectedQuarter, year: selectedYear });
                    close();
                  }}
                  type="button"
                  className={classnames('btn btn-success btn-sm', styles.gotoButton)}
                  disabled={!selectedQuarter}
                >
                  Go to <Icon>arrow_forward</Icon>
                </button>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
