import { useEffect, useState } from 'react';

/**
 *
 * This hook is used to inject an onload event into an iframe/element.
 * The event will send a postMessage depending on the message and targetOrigin provided.
 *
 * @param {Object} obj
 * @param {Object} obj.ref - the ref to the iframe/element
 * @param {Object} obj.message - the message to send
 * @param {Object} obj.targetOrigin - the target origin to send with the message
 * @returns {void}
 */
export default function useOnloadPostMessage({ ref, message, targetOrigin }) {
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.onload = () => {
        if (!sent) {
          ref.current.contentWindow.postMessage(message, targetOrigin);
          setSent(true);
        }
      };
    }
  }, [ref, message, targetOrigin, sent]);
}
