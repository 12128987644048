import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { toFormat } from './utils';

const Input = ({
  name,
  value,
  onChange,
  onFocus,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/DD/YYYY',
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(toFormat(value, dateFormat));
  }, [value]);

  const handleOnChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChange(name, event.target.value);
    }
  };

  return (
    <input
      type="text"
      className="DefaultDatePicker__input"
      name={name}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      value={inputValue}
      onFocus={onFocus}
      data-fx-name={_.camelCase(name + 'Value')}
    />
  );
};

export default Input;
