import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import classnames from 'classnames';

import styles from './SlidePane.module.css';

const SlidePane = ({
  headerContent = '',
  headerClass = '',
  children,
  show = false,
  showCloseBtn = true,
  onClose = () => {},
  backdrop = true,
  contentClassName = '',
  bodyClass = '',
  position = 'right',
  modalSize = 'small',
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop={backdrop}
      keyboard={false}
      className={classnames(styles.slidePane, {
        [styles.right]: position === 'right',
        [styles.left]: position === 'left',
      })}
      contentClassName={contentClassName}
      dialogClassName={{
        [styles.lg]: modalSize === 'large',
      }}
    >
      {headerContent && (
        <ModalHeader closeButton={showCloseBtn} className={headerClass}>
          {headerContent}
        </ModalHeader>
      )}
      <ModalBody className={bodyClass}>{children}</ModalBody>
    </Modal>
  );
};

export default SlidePane;
