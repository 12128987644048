import classnames from 'classnames';
import useCompanyIndustry from '../hooks/useCompanyIndustry';

import CompanyStats from './CompanyStats';
import { PartnershipLogos, ScanToolLogos } from './PartnerLogos';

import styles from './Subheader.module.css';

const SubHeader = ({ companyId }) => {
  const { data: industry } = useCompanyIndustry(companyId);

  return (
    <div className={classnames(styles.wrapper, 'd-flex bg-white')}>
      <div className="w-100 mr-3">
        <div className="d-flex flex-wrap mb-3 align-items-center justify-content-center justify-content-lg-start text-center text-lg-left">
          <h2 className="mb-0 font-28 bold" data-fx-name="contributorsHeader">
            {industry?.industry} Contributors & Expertise
          </h2>
        </div>
        <CompanyStats companyId={companyId} />
      </div>

      <div
        className={classnames(
          styles.borderColor,
          'd-flex align-items-center flex-shrink-0 border rounded py-3'
        )}
        data-fx-name="partnersImageContainer"
      >
        <div className="d-flex flex-column">
          <PartnershipLogos />
          <div className={classnames(styles.borderColor, 'border-top my-3')}></div>
          <ScanToolLogos className="align-items-center" />
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
