import { useState } from 'react';
import { If, Then, Else } from 'react-if';
import classnames from 'classnames';
import { Paper, Spinner } from '@webfx/core-web';
import { useContributors, useFlags } from '@webfx/web-hooks';
import useCompanyIndustry from './hooks/useCompanyIndustry';
import Sidebar from './Sidebar';
import SidePanel from './SidePanel';
import TeamBlock from './TeamBlock';
import TitleBar from './TitleBar';
import Header from './Header';
import Subheader from './SubHeader';

import styles from './Contributors.module.css';

const Contributors = ({ companyId, fullWidth = false, showSidebar = true, isEmbed = false }) => {
  const [showPanel, setShowPanel] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { data, isLoading } = useContributors(companyId);
  const { data: industry } = useCompanyIndustry(companyId);
  const flags = useFlags();

  let workstackTeam = null;
  if (data?.workstacks) {
    workstackTeam = {
      icon: 'bar_chart',
      name: 'Your Supporting Specialized Knowledge & Work Stack Teams',
      users: data.workstacks,
    };
  }
  const handleUserClick = (user) => {
    const hasSMEs = !!(user?.['SMEs']?.length > 0);

    if (hasSMEs) {
      setCurrentUser(user);
      setShowPanel(true);
    }
  };

  const handlePanelClose = () => {
    setShowPanel(false);
    setCurrentUser({});
  };

  return (
    <>
      <If condition={isLoading}>
        <Then>
          <Spinner className="mt-4" />
        </Then>
        <Else>
          {flags.analyticsfxContributors ? (
            <Header companyId={companyId} isEmbed={isEmbed} />
          ) : null}
          {flags.analyticsfxContributors ? (
            <Subheader companyId={companyId} />
          ) : (
            <TitleBar companyId={companyId} />
          )}

          <Paper className={classnames(styles.content, fullWidth && styles.fullWidthContent)}>
            <div className="row">
              <div
                className={classnames(
                  {
                    'col-lg-9': flags.analyticsfxContributors,
                    'col-lg-10': showSidebar && !flags.analyticsfxContributors,
                  },
                  'col-12'
                )}
              >
                <div className="d-flex flex-wrap mb-3 align-items-center justify-content-center justify-content-lg-start text-center text-lg-left">
                  <h3 className="mb-0 font-24 bold" data-fx-name="label">
                    Contributors On Your Account
                  </h3>
                  <span
                    className="d-flex ml-3 badge badge-pill bg-green-02 text-green-04 font-14"
                    data-fx-name="label"
                  >
                    Past 12 Months
                  </span>
                </div>
                {data?.teams.map((team, idx) => (
                  <TeamBlock
                    onUserClick={handleUserClick}
                    team={team}
                    variant={idx === 0 ? 'primary' : null}
                    key={`team-block-${idx}`}
                    industry={industry}
                  />
                ))}
                {workstackTeam ? (
                  <TeamBlock
                    onUserClick={handleUserClick}
                    team={workstackTeam}
                    variant="workstack"
                    industry={industry}
                  />
                ) : null}
              </div>
              {showSidebar ? (
                <div className={classnames('col mt-4 mt-lg-0')}>
                  <Sidebar data={data} companyId={companyId} industry={industry} />
                </div>
              ) : null}
            </div>
          </Paper>
        </Else>
      </If>
      {currentUser && showPanel && (
        <SidePanel user={currentUser} show={showPanel} onClose={handlePanelClose} />
      )}
    </>
  );
};

export default Contributors;
