import { utils } from '@webfx/core-web';
import { useMutation, useQueryClient } from 'react-query';

const useFeathersMutation = (service, method, primaryKey, keys = []) => {
  const { feathersMutation } = utils;
  const queryClient = useQueryClient();

  const mutation = useMutation(feathersMutation(service, method), {
    onSuccess: (record) => {
      for (const key of keys) {
        const [, keyMethod] = key;
        const oldData = queryClient.getQueryData(key);

        if (['patch', 'put'].includes(method.toLowerCase())) {
          if (keyMethod === 'find') {
            if (Array.isArray(oldData)) {
              queryClient.setQueryData(
                key,
                oldData.map((item) => {
                  return record[primaryKey] === item[primaryKey] ? record : item;
                })
              );

              return;
            }

            queryClient.setQueryData(key, {
              ...oldData,
              data: oldData.data.map((item) => {
                return record[primaryKey] === item[primaryKey] ? record : item;
              }),
            });

            continue;
          }
        }

        if (method.toLowerCase() === 'create') {
          if (Array.isArray(oldData)) {
            queryClient.setQueryData(key, oldData.concat(record));

            continue;
          }

          queryClient.setQueryData(key, {
            ...oldData,
            data: oldData.concat(record),
            total: oldData.total + 1,
          });

          continue;
        }

        if (method.toLowerCase() === 'remove') {
          if (Array.isArray(oldData)) {
            queryClient.setQueryData(
              key,
              oldData.filter((item) => {
                return record[primaryKey] !== item[primaryKey];
              })
            );

            continue;
          }

          queryClient.setQueryData(key, {
            ...oldData,
            data: oldData.data.filter((item) => {
              return record[primaryKey] !== item[primaryKey];
            }),
          });

          continue;
        }
      }
    },
  });

  return mutation;
};

export default useFeathersMutation;
