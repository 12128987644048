import { api } from '@webfx/core-web';
import { useNotifications } from '@webfx/web-hooks';
import usePersonSearch from './usePersonSearch';

/**
 * Mutator for creating a contact for a person
 * @param {string} prospectType  - The type of prospect to create
 * @param {boolean} dryRun  - Dry run the request
 * @param {boolean} enableToast - Enable toast notifications
 * @param {?string} calledBy - The product invoking the hook. Used for logging purposes
 * @returns {*}
 */
export default function useCreateContact(
  prospectType = 'prospectorfx',
  dryRun = false,
  enableToast = true,
  calledBy
) {
  const queryClient = api.useQueryClient();
  const { queryKey, refetch } = usePersonSearch(undefined, undefined, calledBy);
  const { toast } = useNotifications();

  const mutator = api.useMutation(
    [`/mcfx/reveal-prospect?prospectType=${prospectType}&dryRun=${dryRun}`],
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey);
        await queryClient.invalidateQueries('person-detail');
        await setTimeout(5000);
        await queryClient.invalidateQueries('iam/usage');
        await refetch();
        await queryClient.invalidateQueries(['idp', 'auto-prospect']);

        if (enableToast) {
          toast.success('Contact created successfully');
        }
      },
      onError: async (error) => {
        if (enableToast) {
          toast.error(error.message);
        }
      },
    }
  );

  return {
    ...mutator,
    mutate: (data, opts) => {
      return mutator.mutate(createData(data), opts);
    },
    mutateAsync: async (data, opts) => {
      return await mutator.mutateAsync(createData(data), opts);
    },
  };
}

const createData = (data) => {
  const { siteId, prospectId } = data;

  return {
    siteId,
    prospectId,
  };
};
