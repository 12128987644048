import React from 'react';
import classnames from 'classnames';

import styles from './Callout.module.css';

const Callout = ({ children }) => {
  return <div className={classnames('ml-5 mr-5 pl-5 pr-5', styles.container)}> {children}</div>;
};

export default Callout;
