import classnames from 'classnames';
import { Image } from '@webfx/core-web';
import styles from '../Subheader.module.css';

const PartnerLogo = ({ src, alt, className, dataFxName }) => {
  return (
    <Image
      src={src}
      alt={alt}
      srcSet={true}
      className={classnames(styles.partnerLogo, className)}
      data-fx-name={dataFxName}
    />
  );
};

export default PartnerLogo;
