import classnames from 'classnames';
import { Icon, Link } from '@webfx/core-web';
import { useContributors, useActiveSite, useRouter } from '@webfx/web-hooks';
import useCompanyIndustry from '../hooks/useCompanyIndustry';

import styles from './Subheader.module.css';

const computeExpertiseValue = (hours) => {
  const yearlyWorkHours = 2000;

  const years = hours / yearlyWorkHours;
  const total = years < 1 ? hours : years;

  return Math.round(total).toLocaleString();
};

const Stat = ({ icon, stat, label, footer }) => {
  const expertise = computeExpertiseValue(stat);

  return (
    <div className={classnames(styles.stat, 'd-flex align-items-start')}>
      <Icon className="mt-1 px-2 text-blue-vivid-300 font-32" outlined>
        {icon}
      </Icon>
      <div className={styles.statContent}>
        <div
          className="font-24 bold"
          data-fx-name="totalCompanyStats"
          style={{ lineHeight: '1.4em' }}
        >
          {expertise}
        </div>
        <p
          className="font-12 mb-0"
          data-fx-name="companyStatsLabel"
          style={{ lineHeight: '1.2em' }}
        >
          {label}
        </p>
        {footer}
      </div>
    </div>
  );
};

const CompanyStats = ({ companyId, fromPlaybook = false }) => {
  const { data: industry } = useCompanyIndustry(companyId);
  const { siteId } = useActiveSite();
  const { url } = useRouter();
  const { data } = useContributors(companyId);
  const { companyStats, globalStats } = data || {};

  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
      <div className={styles.statsGrid}>
        {companyStats?.contributors ? (
          <div className="d-flex flex-column">
            <Stat
              icon="group"
              stat={companyStats?.contributors}
              label="Total Expert Contributors Who Have Worked On Your Account (Past 12 Months)"
              footer={
                fromPlaybook ? (
                  <Link
                    className="semibold font-11"
                    to={url({
                      route: 'mcfxAnalyticsContributors',
                      siteId,
                    })}
                    plain={true}
                  >
                    View Full Team
                  </Link>
                ) : null
              }
            />
          </div>
        ) : null}
        {companyStats?.contributors_w_smes ? (
          <Stat
            icon="bar_chart"
            stat={companyStats?.contributors_w_smes}
            label="Total Certified Subject Matter Experts Who Have Worked On Your Account (Past 12 Months)"
          />
        ) : null}
        {companyStats?.industry_hours ? (
          <Stat
            icon="schedule"
            stat={companyStats?.industry_hours}
            label={`Combined WebFX ${
              companyStats?.industry_hours <= 2000 ? 'Hours' : 'Years'
            } in the ${industry?.industry} Center of Excellence`}
          />
        ) : null}
        {globalStats?.companywide_hours ? (
          <Stat
            icon="business"
            stat={globalStats?.companywide_hours}
            label={`Company-wide Knowledge & Expertise ${
              globalStats?.companywide_hours <= 2000 ? 'Hours' : 'Years'
            }`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CompanyStats;
