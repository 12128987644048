import { utils } from '@webfx/core-web';
import useCancelablePromises from './useCancelablePromises';

function useDoubleClick(onClick, onDoubleClick) {
  const { cancelablePromise, delay } = utils;
  const api = useCancelablePromises();

  const handleClick = (event) => {
    event.persist();
    api.clearPendingPromises();
    const waitForClick = cancelablePromise(delay(200));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick(event);
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = (event) => {
    event.persist();
    api.clearPendingPromises();
    onDoubleClick(event);
  };

  return [handleClick, handleDoubleClick];
}

export default useDoubleClick;
