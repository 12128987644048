import { createContext } from 'react';
import { defineAbility } from '@casl/ability';
import { mapValuesKey } from 'zustand-x';
import { AuthStore } from './auth';
import { MenuStore } from './menu';
import { LayoutStore } from './layout';
export { useVisibilityRepersist } from './useVisibilityRepersist';

// Global store
export const rootStore = {
  auth: AuthStore,
  menu: MenuStore,
  layout: LayoutStore,
};

/**
 * Global hook that returns the store object.
 * @returns {object} The store object.
 */
export const useStore = () => mapValuesKey('use', rootStore);

/**
 * Global hook that returns a tracked store using the `useTracked` function.
 * @returns {object} The tracked store.
 */
export const useTrackedStore = () => mapValuesKey('useTracked', rootStore);

// Global getter selectors
export const store = mapValuesKey('get', rootStore);

export const api = mapValuesKey('store', rootStore);

// Global actions
export const actions = mapValuesKey('set', rootStore);

export const PermissionsAbility = defineAbility((can, cannot) => {
  cannot('owner');
  cannot('readMultipleProjects');
  cannot('read', 'twfx');
  cannot('read', 'mcfx');
  cannot('read', 'cgfx');
  cannot('read', 'chatfx"');
  cannot('read', 'nutshell');
  cannot('read', 'opsfx"');
  can('read', 'isDefaults');
});

export const PermissionsContext = createContext();
