import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Generates Nutshell Authentication
 * @param {string} targetSiteId - (optional) The siteId to generate auth for
 * @param {string} authFor - The type of auth to generate (crm or embed)
 * @param {string} instanceId - The instanceId to generate auth for - only used for admin embed auth
 * @returns {*}
 */
export default function useNutshellAuth({ targetSiteId = null, authFor = 'crm', instanceId } = {}) {
  const siteId = useActiveSite()?.siteId ?? targetSiteId;

  const auth = api.useQuery(
    [`nutshell/auth`, { siteId, for: authFor, ...(instanceId ? { instanceId } : {}) }],
    {
      retry: 1,
      retryOnMount: false,
      enabled: !!siteId || !!instanceId,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  return auth;
}
