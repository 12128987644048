import moment from 'moment';

/**
 * Convert seconds to H:mm:ss
 * @param seconds
 * @returns {string}
 */
const useFormatSeconds = (seconds) => {
  if (!seconds) {
    return 'N/A';
  }

  return moment().startOf('day').seconds(seconds).format('H:mm:ss');
};

export default useFormatSeconds;
