import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Handles processing plan upgrades
 * @param {integer} siteId
 * @returns {object} - newly updated plan
 */
const useSitePlanMutator = (siteId) => {
  const { siteId: activeSiteId } = useActiveSite();
  const currentSiteId = siteId ?? activeSiteId;
  const queryClient = api.useQueryClient();

  return api.useMutation(['iam/plans'], {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['/iam/plans', { siteId: currentSiteId }] });
    },
  });
};

export default useSitePlanMutator;
