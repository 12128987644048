import { api } from '@webfx/core-web';

/**
 * Hook to get industry data for a company.
 *
 * @param {number} companyId
 * @returns {object}
 */
function useCompanyIndustry(companyId) {
  return api.useQuery(['contributors/industry', Number(companyId)], { enabled: !!companyId });
}

export default useCompanyIndustry;
