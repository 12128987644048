import React from 'react';
import classnames from 'classnames';
import { Image } from '@webfx/core-web';

import styles from './FeatureBlock.module.css';

const FeatureBlock = ({
  image,
  imagePosition = 'left',
  description,
  className,
  ...passThroughProps
}) => {
  return (
    <div className={classnames(['container-fluid', className])} {...passThroughProps}>
      <div className="row">
        <div
          className={classnames('col-md-6', {
            'order-2': imagePosition === 'right',
          })}
        >
          <Image src={image} className={styles.image} />
        </div>
        <div
          className={classnames('col-md-6', {
            'order-1': imagePosition === 'right',
          })}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default FeatureBlock;
