import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { actions } from '@webfx/web-state';
import { useRouter } from '@webfx/web-hooks';

/**
 * Uses the banner action
 *
 * @param {*} bannerDetails
 */
const useBanner = (bannerDetails = {}) => {
  const setBanner = actions.layout.setBanner;
  const { active } = useRouter();
  const { pathname } = useLocation();

  useEffect(() => {
    setBanner(bannerDetails);

    return () => {
      setBanner(null);
    };
  }, [active, pathname]);
};

export default useBanner;
