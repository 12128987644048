import { useQueries } from 'react-query';
import { feathers } from '@webfx/core-web';

const useFeathersQueries = (queries) => {
  const queriesKeys = queries.map((q) => [q[0], q[1], q[2]?.query]);

  const queriesMap = queries.map((q, index) => {
    const [service, method, params] = q;
    return {
      queryKey: queriesKeys[index],
      queryFn: () => feathers().service(service)[method](params),
    };
  });

  const results = useQueries(queriesMap);

  results.forEach((result, index) => {
    result.queryKey = queriesKeys[index];
  });

  return results;
};

export default useFeathersQueries;
