import { useMemo } from 'react';
import _ from 'lodash';

const useChartConfig = ({ type, colors, dataLength, options = {} }) =>
  useMemo(() => {
    // Hack to work around the shade property not working
    // Leaving this here for now until I know if the transparent chart actually needs to be there
    const colorReplaced = colors
      ? colors.map((color) => (color === 'transparent' ? '#FFFFFF' : color))
      : null;

    const areaOptions = {
      chart: {
        height: 100,
        width: 100,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: 'straight',
        colors,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: colorReplaced,
        type: 'gradient',
        gradient: {
          type: 'vertical',
          gradientToColors: colorReplaced,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          format: 'dd/MM/yy',
        },
        marker: {
          show: false,
        },
      },
      legend: {
        markers: {
          fillColors: colors,
        },
      },
      markers: {
        colors,
      },
      xaxis: {
        type: 'date',
        categories: [
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEPT',
          'OCT',
          'NOV',
          'DIC',
          'JAN',
        ],
      },
    };

    const donutOptions = {
      chart: {
        height: 100,
        width: 100,
        type: 'donut',
        toolbar: {
          show: false,
        },
      },
      title: {
        align: 'center',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors,
    };

    const sparkOptions = {
      chart: {
        type: 'line',
        height: 35,
        width: 100,
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: 'straight',
        colors,
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
    };

    const columnOptions = {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      colors,
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'date',
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    };

    const defaultOptions = {
      chart: {
        height: 100,
        width: 100,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: 'straight',
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          format: 'dd/MM/yy',
        },
      },
      xaxis: {
        type: 'date',
        categories: [
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEPT',
          'OCT',
          'NOV',
          'DIC',
          'JAN',
        ],
      },
    };

    let config = {};

    switch (type) {
      case 'area':
        config = areaOptions;
        break;
      case 'donut':
        config = {
          ...donutOptions,
          ...(dataLength === undefined || dataLength > 0
            ? {
                stroke: {
                  show: false,
                },
              }
            : {
                stroke: {
                  colors: ['#6b6b6b'],
                  width: 1,
                },
                colors: ['#fff'],
              }),
        };
        break;
      case 'spark':
        config = sparkOptions;
        break;
      case 'column':
        config = columnOptions;
        break;

      default:
        config = defaultOptions;
        break;
    }

    return _.merge(config, options);
  }, [type, colors, dataLength, options]);

export default useChartConfig;
