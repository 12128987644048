import { createStore } from 'zustand-x';

const defaults = {
  route: null,
  logo: null,
  copy: null,
  hideSelector: false,
  retainParams: false,
};

export const LayoutStore = createStore('layout')({
  banner: {
    ...defaults,
  },
}).extendActions((set, get) => ({
  setBanner: (payload) => {
    set.banner(
      payload
        ? {
            ...get.banner(),
            ...payload,
            // these enforce resets to the banner state
            // only used when called explicitly and should return to defaults
            ...(!payload.route && { route: null }),
            ...(!payload.hideSelector && { hideSelector: false }),
            ...(!payload.retainParams && { retainParams: false }),
          }
        : defaults
    );
  },
}));
