import { useState, useEffect } from 'react';
import { feathers } from '@webfx/core-web';

const usePoolWorkerJob = (jobString, opts = {}) => {
  const intervalSeconds = opts.intervalSeconds || 3000;
  const [jobData, setJobData] = useState(null);
  const [isLoading, setIsLoading] = useState(!!jobString);
  let interval;

  useEffect(() => {
    if (!jobString) {
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }

    setIsLoading(true);

    const [queue, jobId] = jobString.split('/');

    interval = setInterval(async () => {
      try {
        const jobResponse = await feathers().service('queues').get(jobId, { query: { queue } });
        setJobData({
          ...jobResponse,
          retry: async () => {
            feathers()
              .service('queues')
              .get(jobId, { query: { retry: true, queue } });
            setJobData({
              ...jobData,
              state: 'waiting',
            });
          },
        });

        setIsLoading(false);

        if (jobResponse.state === 'completed') {
          clearInterval(interval);
        }
      } catch (e) {
        setJobData(null);
        setIsLoading(false);
        clearInterval(interval);
      }
    }, intervalSeconds);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [jobString]);

  return [jobData, isLoading];
};

export default usePoolWorkerJob;
