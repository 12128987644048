import { useStoreActions, useStoreState } from 'easy-peasy';

const useEnabledCompanies = () => {
  const isLoading = useStoreState((state) => state.coreCompanies.loader.enabledCompanies);
  const enabledCompanies = useStoreState((state) => state.coreCompanies.enabledCompanies);
  const fetchEnabledCompanies = useStoreActions(
    (actions) => actions.coreCompanies.fetchEnabledCompanies
  );

  if (enabledCompanies === null) {
    fetchEnabledCompanies();
  }

  return {
    isLoading,
    enabledCompanies,
  };
};

export default useEnabledCompanies;
