import React, { useEffect, useState, useRef } from 'react';
import { Input } from '@webfx/core-web';
import useCompanyUsers from '@webfx/permissions-web/src/hooks/useCompanyUsers';
import { useActiveSite } from '@webfx/web-hooks';
import { useResize } from '@webfx/web-context';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import { VariableSizeList as List } from 'react-window';
import DropdownItem from '../DropdownItem';
import styles from './Menu.module.css';

const RowItem = React.forwardRef(({ data, index, style }, ref) => {
  return (
    <Dropdown.Item
      ref={ref}
      eventKey={data[index]}
      key={data[index].userId}
      index={index}
      as={DropdownItem}
      data={data[index]}
      style={style}
    >
      {`${data[index].firstName ?? data[index].email} ${data[index].lastName ?? ''}`}
    </Dropdown.Item>
  );
});

const Menu = React.forwardRef(({ onSearch }, ref) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const activeSite = useActiveSite();
  const { setRef, size: getSize } = useResize();
  const listRef = useRef();
  const listOuterRef = useRef();

  useEffect(() => {
    setRef(listRef);
  }, []);

  const { data: companyUsers, isLoading } = useCompanyUsers(
    activeSite?.companyId,
    {},
    {
      $join: {
        user: {
          $sort: {
            lastName: 1,
          },
          $join: {
            status: true,
            sites: { $join: ['encryption'] },
            products: true,
            tokens: true,
          },
        },
      },
      $limit: -1,
    }
  );

  const userData =
    companyUsers
      ?.filter(({ active, user }) => {
        if (!active || !user) {
          return false;
        }
        return (
          user?.type !== 'admin' &&
          !user?.sites.some((site) => site.encryption) &&
          (user?.firstName?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
            user?.lastName?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
            user?.email?.toLowerCase().includes(debouncedSearch.toLowerCase()))
        );
      })
      ?.map(({ user }) => user) ?? [];

  return (
    <div ref={ref} className={styles.menuWrapper}>
      <div className={styles.searchWrapper}>
        <Input
          className="mb-0"
          variant="sm"
          icon="search"
          iconPosition="left"
          data-fx-name="searchClientProfile"
          onChange={(e) => {
            setSearch(e.target.value);
            if (onSearch) {
              onSearch(e);
            }
          }}
          value={search}
          clearable={true}
          onClear={() => {
            setSearch('');
          }}
          autoFocus
        />
      </div>
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" data-fx-name="searchClientProfileSpinner" />
        </div>
      ) : null}
      <List
        className="select-menu-items"
        outerRef={listOuterRef}
        innerElementType={({ children }) => <div>{children}</div>}
        ref={listRef}
        height={200}
        itemSize={getSize}
        itemCount={userData?.length ?? 0}
        width="100%"
        itemData={userData ?? []}
        estimatedItemSize={60}
      >
        {RowItem}
      </List>
    </div>
  );
});

export default Menu;
