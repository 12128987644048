import { useState } from 'react';

const usePagination = () => {
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [current, setCurrent] = useState({
    total: 0,
    skip: 0,
    limit: 0,
  });

  return {
    limit,
    setLimit,
    skip,
    setSkip,
    current,
    setCurrent,
  };
};

export default usePagination;
