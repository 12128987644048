import { useEffect } from 'react';
import { ErrorPage, Link } from '@webfx/core-web';
import { useRouter, useCan } from '@webfx/web-hooks';
import { useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';

export function Unauthorized() {
  const { url } = useRouter();
  const { pathname } = useLocation();
  const hasTWPermission = useCan('read', 'twfx');
  const hasMCFXPermission = useCan('read', 'mcfx');
  const hasCGFXPermission = useCan('read', 'cgfx');
  const hasNutshellPermission = useCan('read', 'nutshell');
  const { setActive, setLastActiveForce } = useStoreActions((store) => store.sites);

  useEffect(() => {
    return () => {
      setActive(null);
      setLastActiveForce(null);
    };
  }, [pathname]);

  return (
    <ErrorPage title="Unauthorized" subtitle="You do not have permission to view this page.">
      <div>
        <p>Please try one of the links below to find what you are looking for:</p>
        <ul className="list-inline mt-4">
          {hasTWPermission ? (
            <li className="list-inline-item mr-5">
              <Link to="/dashboard">Teamwork</Link>
            </li>
          ) : null}
          {hasMCFXPermission ? (
            <li className="list-inline-item mr-5">
              <Link to={url({ route: 'mcfxDashboard' })}>MarketingCloudFX</Link>
            </li>
          ) : null}
          {hasCGFXPermission ? (
            <li className="list-inline-item mr-5">
              <Link to="/contentgenius">ContentGeniusFX</Link>
            </li>
          ) : null}
          {hasNutshellPermission ? (
            <li className="list-inline-item ">
              <Link to="/nutshell">Nutshell</Link>
            </li>
          ) : null}
        </ul>
      </div>

      <div className="mt-auto pb-5">
        <hr className="mt-5 mb-3 p-0 mx-0" style={{ width: '50px', borderColor: '#000' }} />
        <p>
          Still can&apos;t find what you&apos;re looking for?
          <br />
          Email our support desk at <a href="mailto:app@webfx.com">app@webfx.com</a>.
        </p>
      </div>
    </ErrorPage>
  );
}
