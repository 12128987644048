import { feathers } from '@webfx/core-web';
import { useActiveSite, useAuth } from '@webfx/web-hooks';

export default function useSharedLink() {
  const user = useAuth().user;
  const { siteId } = useActiveSite();

  const generateShareLink = async ({ path, accessibleUrls }) => {
    try {
      const { token } = await feathers()
        .service('publicTokens')
        .create({
          /**
           * 1. Accepts array to allow multiple accessible urls for guest user
           * 2. Transform the array of accessibleUrls into a string as the role field does not accept arrays
           */
          role: accessibleUrls.join(','),
          siteId: siteId ?? user?.sites[0]?.siteId,
        });

      const generatedLink = `${process.env.POI_APP_URL}${path}?accessToken=${token}`;
      return generatedLink;
    } catch (error) {
      console.error('Error generating shared link:', error);
      return null;
    }
  };

  return { generateShareLink };
}
