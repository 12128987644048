import { useAbility } from '@casl/react';
import { PermissionsContext } from '@webfx/web-state';

export function usePermissions() {
  const ability = useAbility(PermissionsContext);

  return ability;
}

export default usePermissions;
