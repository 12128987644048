import { Icon } from '@webfx/core-web';
import classnames from 'classnames';

const statusIcons = {
  Active: { icon: 'circle', className: 'text-green-04' },
  Paused: { icon: 'pause', className: 'text-gray-500' },
  Removed: { icon: 'clear', className: 'text-red-04' },
  Unknown: { icon: 'horizontal_rule', className: 'text-gray-500' },
  Archived: { icon: 'archive', className: 'text-blue-vivid-300' },
  Completed: { icon: 'check_circle', className: 'text-green-04' },
  Canceled: { icon: 'cancel', className: 'text-red-04' },
  Expired: { icon: 'timer_off', className: 'text-orange-04' },
  ['Starts In Future']: { icon: 'event', className: 'text-blue-vivid-300' },
};

const StatusIcon = ({ status }) => {
  const statusIcon = statusIcons[status];
  if (!status) {
    return null;
  }

  return (
    <Icon
      title={status}
      className={classnames(
        `text-center font-weight-bold mx-3 font-24 p-0 ${statusIcon.className}`
      )}
      data-fx-name="statusIcon"
    >
      {statusIcon.icon}
    </Icon>
  );
};

export default StatusIcon;
