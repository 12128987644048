import { actions, useStore } from '@webfx/web-state';

/**
 * Returns the menu state information
 * @returns {object}
 */
export default function useMenu() {
  const store = useStore();
  return {
    // state
    menuOpen: store.menu.menuOpen(),
    showSubMenu: store.menu.showSubMenu(),
    animation: store.menu.animation(),

    // actions
    setShowSubMenu: actions.menu.showSubMenu,
    setMenuOpen: actions.menu.menuOpen,
    setAnimation: actions.menu.animation,
  };
}
