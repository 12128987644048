import { api } from '@webfx/core-web';

/**
 * Returns WebFX companies:
 * 1. Any with "WebFX" in the name
 * 2. "Copy Squad"
 *
 * @returns {object[]}
 */
export default function useWebFXCompanies() {
  return api.useQuery(
    [
      'companies',
      {
        query: {
          // 3763030 = "Copy Squad" company
          $or: [{ name: { $like: '%WebFX%' } }, { companyId: { $in: [3763030] } }],
          $limit: -1,
        },
      },
    ],
    {
      placeholderData: [],
      select: (data) => data.data ?? data ?? [],
      cacheTime: 1000 * 60 * 30, // 30 min
      staleTime: 1000 * 60 * 10,
    }
  );
}
