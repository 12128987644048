import useSitePlan from './useSitePlan';

/**
 * Checks site-plan if its on a foundation + plan
 * @param {boolean} only - if site has access, check if plan is ONLY 'foundation'
 * @returns {boolean} - true if 'foundation +', false if NOT
 */
const useIsPlanFoundationPlus = (only = false) => {
  const sitePlan = useSitePlan();

  let hasAccess = false;

  if (
    sitePlan?.meta?.isFoundation ||
    sitePlan?.meta?.isProfessional ||
    sitePlan?.meta?.isEnterprise
  ) {
    hasAccess = true;
  }

  // assert caller 'hasAccess' if 'only' check
  if (hasAccess && only) {
    hasAccess = sitePlan?.meta?.isFoundation !== undefined;
  }

  return hasAccess;
};

export default useIsPlanFoundationPlus;
