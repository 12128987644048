import { useQuery } from 'react-query';
import { feathers } from '@webfx/core-web';

const useFeathersQuery = (service, method, params, options = {}) => {
  const queryKey = [service, method, params.query];

  const query = useQuery(
    queryKey,
    () => {
      return feathers().service(service)[method](params);
    },
    options
  );

  return {
    ...query,
    queryKey,
  };
};

export default useFeathersQuery;
