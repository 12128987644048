import { useCallback } from 'react';
import { api } from '@webfx/core-web';
import useNotifications from './useNotifications';
export function useResetPassword() {
  const { toast } = useNotifications();
  const mutator = api.useMutation(['reset-password'], {
    onSuccess: () => {
      toast.success('Password reset instruction sent to email');
    },
  });

  return useCallback((email) => mutator.mutateAsync({ _method: 'create', email }), [mutator]);
}
