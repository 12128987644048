import { api } from '@webfx/core-web';
import useAuth from './useAuth';

const useRefreshPermissions = () => {
  const { user } = useAuth();
  const queryClient = api.useQueryClient();

  queryClient.invalidateQueries(['permissions', user.userId], {
    refetchActive: true,
  });
};

export default useRefreshPermissions;
