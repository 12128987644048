import { useState } from 'react';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import isUndefined from 'lodash/isUndefined';
import classnames from 'classnames';
import { Button, Checkbox, Icon, Input } from '@webfx/core-web';
import { useLocalStorage } from '@webfx/web-hooks';
import { SlidePane, Switch } from '@webfx/web-ui';

import styles from './FlagOverridesWidget.module.css';

export const FlagOverridesWidget = () => {
  const { flagOverrides: useOverrides, ...ldFlags } = useLDFlags();
  const [flagOverrides, setFlagOverrides] = useLocalStorage('flag-overrides', {});
  const [flagFavorites, setFlagFavorites] = useLocalStorage('flag-favorites', []);

  const [showWidget, setShowWidget] = useState(false);
  const [textFilter, setTextFilter] = useState('');
  const [filterByOverrides, setFilterByOverrides] = useState(false);
  const [filterByFavorites, setFilterByFavorites] = useState(false);
  const [favorited, setFavorited] = useState(null);

  const flags = Object.entries(ldFlags).filter(([flag]) => {
    if (filterByOverrides && isUndefined(flagOverrides[flag])) {
      return false;
    }
    if (filterByFavorites && !flagFavorites.includes(flag)) {
      return false;
    }
    return flag.toLowerCase().includes(textFilter.toLowerCase());
  });

  const handleFilter = (e) => {
    setTextFilter(e.target.value);
  };

  const handleReset = () => {
    setFlagOverrides({});
    setFilterByOverrides(false);
  };

  const handleFavorite = (flag, newValue) => {
    if (!newValue) {
      const newFavorites = flagFavorites.filter((fav) => fav !== flag);
      setFlagFavorites(newFavorites);
      return;
    }
    setFavorited(flag);
    setFlagFavorites([...flagFavorites, flag]);
  };

  const handleToggle = (flag, value) => {
    if (ldFlags[flag] === value) {
      // If value matches LD default, remove override
      const newFlagOverrides = { ...flagOverrides };
      delete newFlagOverrides[flag];
      setFlagOverrides(newFlagOverrides);
      return;
    }
    setFlagOverrides({ ...flagOverrides, [flag]: value });
  };

  const onClose = () => {
    setShowWidget(false);
  };

  if (!useOverrides) {
    return null;
  }

  return (
    <aside id="flag-overrides-widget">
      <Button
        onClick={() => setShowWidget(() => !showWidget)}
        icon={showWidget ? 'close' : 'flag'}
        className={classnames(
          styles.triggerIcon,
          {
            [styles.active]: showWidget,
            'text-white bg-gray-700 bg-hover-gray-600': showWidget,
            'bg-gray-300 bg-hover-gray-400': !showWidget,
          },
          'ml-2 mb-2 p-2 rounded-circle font-20'
        )}
      />
      <SlidePane show={showWidget} onClose={onClose} position="left" bodyClass="px-0 pt-0 pb-5">
        <div className={classnames(styles.header, 'position-sticky px-4 pt-4 pb-3')}>
          <Input
            value={textFilter}
            className="m-0"
            variant="sm"
            icon="search"
            iconPosition="left"
            onChange={handleFilter}
            clearable={true}
            placeholder="Search flags..."
            onClear={(e) => {
              setTextFilter('');
              e.preventDefault(); // prevent submitting when inside a form
            }}
          />
          <div className="d-flex align-items-center mt-2">
            <Checkbox
              inline
              name="filterByFavorites"
              checked={filterByFavorites}
              value={filterByFavorites}
              onChange={() => setFilterByFavorites(!filterByFavorites)}
              wrapperClassname={styles.checkbox}
              label="Favorites"
              labelClassname={classnames(styles.checkboxLabel, 'font-13 text-white')}
            />
            <Checkbox
              inline
              name="filterByOverrides"
              checked={filterByOverrides}
              value={filterByOverrides}
              onChange={() => setFilterByOverrides(!filterByOverrides)}
              wrapperClassname={styles.checkbox}
              label="Overrides"
              labelClassname={classnames(styles.checkboxLabel, 'font-13 text-white')}
            />
            <Button
              onClick={handleReset}
              variant="outline-gray"
              size="sm"
              className="ml-auto font-13"
              icon="settings_backup_restore"
              iconClassName="pr-1 font-18"
            >
              Reset Overrides
            </Button>
          </div>
        </div>
        {flags.length ? (
          <div className={styles.content}>
            {flags.map(([flag, val], i) => {
              const isOverridden = !isUndefined(flagOverrides[flag]);
              const isChecked = flagOverrides[flag] ?? val;
              const isFavorite = flagFavorites.includes(flag);
              const bgColor = i % 2 === 0 ? 'bg-white' : 'bg-gray-100';
              return (
                <div key={flag} className={`${bgColor} d-flex align-items-center py-2 pr-3`}>
                  <div
                    className={classnames(styles.favoriteWrapper, 'd-flex px-3 cursor-pointer')}
                    onClick={() => handleFavorite(flag, !isFavorite)}
                  >
                    <Icon
                      className={classnames(
                        styles.favoriteIcon,
                        {
                          [styles.favoriteActive]: isFavorite,
                          [styles.favoriteInactive]: !isFavorite,
                          [styles.favoriteClicked]: favorited === flag && isFavorite,
                        },
                        'font-24'
                      )}
                    >
                      favorite
                    </Icon>
                  </div>
                  <Switch
                    className={classnames(styles.toggle, { [styles.checked]: isChecked })}
                    checked={isChecked}
                    label={
                      <span
                        className={classnames(
                          { 'text-orange-04 text-hover-orange-05 bold': isOverridden },
                          'cursor-pointer text-hover-gray-600'
                        )}
                      >
                        {flag}
                      </span>
                    }
                    onChange={(value) => handleToggle(flag, value)}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-2 font-14 text-center">No flags found.</div>
        )}
      </SlidePane>
    </aside>
  );
};
