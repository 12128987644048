import { Svg } from '@webfx/core-web';
import classNames from 'classnames';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styles from './SlidesCarousel.module.css';
import { slideImages, slides } from './slidesConstants';

const SlidesCarousel = () => {
  return (
    <Carousel
      className={classNames('loading-carousel', styles.loadingCarousel)}
      controls={false}
      interval={5000}
    >
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <div className="row mb-5 pb-5">
            <div className="col-6 d-flex align-items-center justify-content-center">
              {<SlideFactory slide={slide} />}
            </div>
            <div className="col-6 pl-3">
              <div className="position-relative">
                <Svg
                  src={slideImages[index].image}
                  svgStyle={{
                    height: '100%',
                    width: slideImages[index].contain ? '200px' : '350px',
                  }}
                  className={styles.slideImages}
                />
                <div className={styles.slideBg}></div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

function SlideFactory({ slide }) {
  const { heading, subHeading, svg, desc, list } = slide;
  return (
    <div>
      {subHeading && <h3 className={styles.subHeading}>{subHeading}</h3>}
      {svg &&
        (svg.withText ? (
          <div className="d-flex mb-3">
            <Svg src={svg.src} className={svg.className} />
            <h2 className={styles.eader}>{svg.withText}</h2>
          </div>
        ) : (
          <Svg src={svg.src} className={svg.className} />
        ))}
      {heading && <h2 className={styles.eader}>{heading}</h2>}
      {desc && <div> {desc} </div>}
      {list && (
        <ul>
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SlidesCarousel;
