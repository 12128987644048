import { Dialog } from '@webfx/web-ui';
import { Icon, Button } from '@webfx/core-web';
import { camelCase } from 'lodash';

/**
 * DeleteModal Component
 *
 * A modal dialog for confirming deletion actions.
 *
 * @component
 * @example
 * // Usage of DeleteModal with Title and Content components
 * import DeleteModal from './DeleteModal';
 * import { useState } from 'react';
 *
 * const MyComponent = () => {
 *     const [isOpen, setIsOpen] = useState(false);
 *
 *     const handleOpen = () => setIsOpen(true);
 *     const handleClose = () => setIsOpen(false);
 *     const handleRemove = () => {
 *         // handle remove action
 *         handleClose();
 *     };
 *
 *     return (
 *         <>
 *             <Button text="Open Modal" onClick={handleOpen} />
 *             <DeleteModal open={isOpen} onClose={handleClose} onRemove={handleRemove}>
 *                 <DeleteModal.Title>Are you sure you want to permanently delete this Automation?</DeleteModal.Title>
 *                 <DeleteModal.Content>
 *                     Deleting this automation is a permanent action and cannot be undone.
 *                     If you are sure you want to proceed, click 'Delete' to permanently remove this automation from the system.
 *                 </DeleteModal.Content>
 *             </DeleteModal>
 *         </>
 *     );
 * };
 *
 * export default MyComponent;
 *
 * @param {object} props - The properties object.
 * @param {boolean} props.open - Determines whether the modal is open.
 * @param {function} props.onClose - Function to call when closing the modal.
 * @param {function} props.onRemove - Function to call when confirming deletion.
 * @param {boolean} props.disabled - Determines whether buttons will be disabled
 * @param {string} [props.cancelButtonText="Cancel"] - Text for the cancel button.
 * @param {string} [props.cancelButtonClassName="btn btn-outline-primary mr-2"] - Class name for the cancel button.
 * @param {string} [props.confirmButtonText="Confirm"] - Text for the confirm button.
 * @param {string} [props.confirmButtonClassName="btn btn-danger"] - Class name for the confirm button.
 * @param {object} props.rest - Additional props to be passed to the Dialog component.
 */
const DeleteModal = ({
  open,
  onClose,
  onRemove,
  disabled = false,
  cancelButtonText = 'Cancel',
  cancelButtonClassName = 'btn btn-outline-primary mr-2',
  confirmButtonText = 'Confirm',
  confirmButtonClassName = 'btn btn-danger',
  children,
  ...rest
}) => {
  return (
    <Dialog open={open} onClose={onClose} className={`w-50 ${rest.className || ''}`} {...rest}>
      <Dialog.Panel>
        <div className="mx-2">
          {children}
          <div className="d-flex justify-content-end w-100 mt-3">
            <Button
              text={cancelButtonText}
              className={cancelButtonClassName}
              disabled={disabled}
              onClick={onClose}
              data-fx-name={`${camelCase(cancelButtonText)}Button`}
            />
            <Button
              text={confirmButtonText}
              className={confirmButtonClassName}
              disabled={disabled}
              onClick={onRemove}
              data-fx-name={`${camelCase(confirmButtonText)}Button`}
            />
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

/**
 * Title Component
 *
 * A subcomponent of DeleteModal for displaying the title with an optional warning icon.
 *
 * @component
 * @example
 * <DeleteModal.Title>Are you sure you want to permanently delete this Automation?</DeleteModal.Title>
 *
 * @param {object} props - The properties object.
 * @param {string} [props.icon="warning"] - The icon to display before the title text.
 * @param {React.ReactNode} props.children - The title text.
 */
const Title = ({ children, icon = 'warning' }) => (
  <div className="d-inline-flex align-items-center mt-4">
    <Icon className="font-24 text-red-05 mr-2">{icon}</Icon>
    <span className="bold" data-fx-name="title">
      {children}
    </span>
  </div>
);

/**
 * Content Component
 *
 * A subcomponent of DeleteModal for displaying the content inside the modal.
 *
 * @component
 * @example
 * <DeleteModal.Content>
 *     Deleting this automation is a permanent action and cannot be undone.
 *     If you are sure you want to proceed, click 'Delete' to permanently remove this automation from the system.
 * </DeleteModal.Content>
 *
 * @param {object} props - The properties object.
 * @param {React.ReactNode} props.children - The content text.
 */
const Content = ({ children }) => (
  <div className="mt-3" data-fx-name="description">
    {children}
  </div>
);

DeleteModal.Title = Title;
DeleteModal.Content = Content;

export default DeleteModal;
