import { createStore } from 'zustand-x';
export const MenuStore = createStore('menu')(
  {
    menuOpen: true,
    showSubMenu: 'none',
    animation: '',
  },
  {
    persist: {
      enabled: true,
    },
  }
).extendActions((set, get) => ({
  menuOpen: (isOpen) => {
    set.animation('showHide');
    set.menuOpen(isOpen);
  },
  showSubMenu: (subMenu) => {
    const prevSubMenu = get.showSubMenu();

    if (get.menuOpen() === false) {
      set.showSubMenu(subMenu);
      return;
    }

    if (prevSubMenu === subMenu || (subMenu !== 'none' && prevSubMenu !== 'none')) {
      set.animation('');
    } else if (subMenu === 'none') {
      set.animation('slideOut');
    } else {
      set.animation('slideIn');
    }
    set.showSubMenu(subMenu);
  },
}));
