import { useState } from 'react';
import styles from './PopOver.module.css';
import classNames from 'classnames';

/**
 * PopOver
 * @param popElement - react element that you want to appear as pop over
 * @param wrapperClass - class for the wrapper
 * @param popElementClass - class for the pop element, you may need to set left:50% on this if you want it centered
 * @param children - element that will trigger the showing/hiding of the popover
 * @returns {JSX.Element}
 * @constructor
 */
const Popover = ({ popElement, wrapperClass, popElementClass, children }) => {
  const [open, setOpen] = useState(false);
  const display = open ? 'block' : 'none';

  return (
    <div className={classNames(styles.popElementWrapper, wrapperClass)}>
      <div
        onClick={() => setOpen(!open)}
        onMouseLeave={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
      >
        {children}
      </div>
      <div style={{ display }}>
        <div className={classNames(styles.popElement, popElementClass)}>{popElement}</div>
      </div>
    </div>
  );
};

export default Popover;
