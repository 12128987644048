import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * returns a mutator that will transform properties
 * back to their corresponding setting shape
 *
 * @returns {object}
 */
export default function useSiteSettingsMutator() {
  const site = useActiveSite({ $join: ['settings'] });
  const mutator = api.useMutation('sites');

  return {
    ...mutator,
    mutate: (data, opts) => {
      mutator.mutate(createData(data, site), opts);
    },

    mutateAsync: async (data, opts) => {
      await mutator.mutateAsync(createData(data, site), opts);
    },
  };
}

const createData = (data, site) => {
  const { _id, _method, ...settings } = data;

  return {
    settings: Object.entries(settings).map(([field, value]) => {
      const orig = site?.settings?.find((setting) => setting.field === field) ?? {};
      return { ...orig, field, value };
    }),
    _id: _id ?? site.siteId,
    _method: _method ?? 'PATCH',
  };
};
