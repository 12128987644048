/**
 * Mapping to add Industry Name to Leadership Title
 * @param {*} title
 * @returns {string} leadershipTitle
 */
const mapLeadershipTitles = ({ industry = '', title }) => {
  const leadershipTitles = {
    'Content Manager': `${industry} Content Manager`,
    'EM Link Builder': `${industry} EM Link Builder`,
    'Industry Strategy Consultant': `${industry} Industry Strategy Consultant`,
  };

  return leadershipTitles[title] || title;
};

export default mapLeadershipTitles;
