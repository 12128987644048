import { useLDClient } from 'launchdarkly-react-client-sdk';
import React from 'react';
import useAuth from './useAuth';

/**
 * authenticates and returns the rules frm launch darkly.
 *
 * @returns {object} client
 */
export default function useLaunchDarkly() {
  const client = useLDClient();
  const user = useAuth().user;
  const userId = useAuth().userId;

  React.useEffect(() => {
    if (!userId || !client) {
      return;
    }

    client.identify({
      kind: 'user',
      key: userId,
      email: user.emails?.[0]?.email ?? user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      teams: user.teams?.map?.((team) => team.name) ?? [],
      companies: user.companies?.map?.((company) => company.companyId) ?? [],
      sites: user.type !== 'admin' ? user.sites?.map?.((site) => site.siteId) ?? [] : [-1],
      accountType: user.type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- userId already enforces user update
  }, [userId, client]);

  return client;
}
