import { toast } from 'react-toastify';
import { NotificationsContainer } from '@webfx/core-web';

function useNotifications() {
  return {
    NotificationsContainer,
    toast,
  };
}

export default useNotifications;
