import useActiveSite from './useActiveSite';
import useDeepMemo from './useDeepMemo';

/**
 * Query and return the value of the active site's settings.
 *
 * @param {string|Array} settings The setting to query.
 * @param {boolean} asNull If true, returns null instead of an empty string. Defaults to false.
 * @returns {*}
 */
export default function useSiteSettings(settings, asNull = false) {
  const site = useActiveSite({ $join: ['settings'] });
  const falsyReturn = asNull ? null : '';
  const values = useDeepMemo(() => {
    if (!site?.settings) {
      return !Array.isArray(settings) ? falsyReturn : {};
    }

    if (!settings) {
      return site.settings.reduce((accum, setting) => {
        accum[setting.field] = setting.value;
        return accum;
      }, {});
    }

    if (!Array.isArray(settings)) {
      const orig = site.settings?.find((s) => s.field === settings);
      return orig?.value ?? falsyReturn;
    }

    return settings.reduce((accum, setting) => {
      const orig = site.settings?.find((s) => s.field === setting);
      accum[setting] = orig?.value ?? falsyReturn;
      return accum;
    }, {});
  }, [site, settings]);

  return values;
}
