import React, { useMemo } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { Icon } from '@webfx/core-web';
import { ResizeProvider } from '@webfx/web-context';
import { useActiveSite, useAuth, useRouter } from '@webfx/web-hooks';

import ViewAsSelector from '../ViewAsSelector';
import Menu from './Menu';
import Toggle from './Toggle';

import styles from './SiteSelector.module.css';

export const SiteSelector = () => {
  const { isAdmin, user } = useAuth();
  const { url } = useRouter();
  const { setActive } = useStoreActions((actions) => actions.sites);

  const history = useHistory();
  const activeSite = useActiveSite({ $join: ['encryption'] });

  const { encryption: requiresEncryption } = activeSite;

  const toggleLabel = useMemo(() => {
    if (activeSite.siteId === null) {
      return isAdmin ? 'Select a Client' : 'Select a Profile';
    }

    return activeSite.name ?? activeSite.url;
  }, [activeSite, isAdmin]);

  if (!isAdmin && user?.sites?.length <= 1) {
    return null;
  }

  return (
    <div>
      <div id="container-div" className={styles.wrapper}>
        <Dropdown className="h-100">
          <Dropdown.Toggle as={Toggle} data-fx-name="selectClientProfile">
            <span className="flex-grow-1 text-left" data-fx-name="currentClientProfile">
              {toggleLabel}
            </span>
          </Dropdown.Toggle>
          {activeSite.siteId ? (
            <div
              className={styles.close}
              onClick={() => {
                setActive({ siteId: null });
                history.push(url({ route: 'mcfxDashboard' }));
              }}
            >
              <Icon>close</Icon>
            </div>
          ) : null}
          <ResizeProvider>
            <div className=" position-relative">
              <div className={classnames('bg-danger position-absolute', styles.menuOuter)}>
                <Dropdown.Menu className={styles.menuWrapper}>
                  <Menu />
                </Dropdown.Menu>
              </div>
            </div>
          </ResizeProvider>
        </Dropdown>
      </div>
      {activeSite.siteId !== null &&
      activeSite.companyId &&
      activeSite.companyId !== 373296 &&
      isAdmin &&
      !requiresEncryption ? (
        <ViewAsSelector />
      ) : null}
    </div>
  );
};
