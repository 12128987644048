import useSitePlan from './useSitePlan';

/**
 * Metadata about a site's plan
 *
 * @typedef {object} Metadata
 * @property {number} planId
 * @property {boolean} isFoundationPlus - Indicates whether a site is on a foundation + plan
 * @property {boolean} isProPlus - Indicates whether a site is on a pro + plan
 */

/**
 * Computes and returns additional metadata about a site's plan
 *
 * @returns {Metadata}
 */
const usePlanMeta = () => {
  const sitePlan = useSitePlan();

  let isFoundationPlus = false;
  let isProPlus = false;

  if (sitePlan?.meta?.isProfessional || sitePlan?.meta?.isEnterprise) {
    isFoundationPlus = true;
    isProPlus = true;
  } else if (sitePlan?.meta?.isFoundation) {
    isFoundationPlus = true;
  }

  return {
    planId: sitePlan?.planId,
    isFoundationPlus,
    isProPlus,
  };
};

export default usePlanMeta;
