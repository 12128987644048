import { actions, useTrackedStore } from '@webfx/web-state';

/**
 * Returns the layout state information
 * @returns {object}
 */
export default function useLayout() {
  const store = useTrackedStore();
  const banner = store.layout.banner();
  return {
    // state
    ...banner,

    // actions
    setBanner: actions.layout.setBanner,
  };
}
