import classnames from 'classnames';
import { Avatar, Icon, Svg } from '@webfx/core-web';

import ribbon from '../../assets/images/svgs/medal-ribbon.svg';
import getColorByHours from '../../utils/get-color-by-hours';

import styles from './UserAvatar.module.css';

const UserAvatar = ({
  user,
  variant,
  handleUserClick = () => {},
  showSMEIcon = true,
  disableLazyLoad = false,
}) => {
  const showSME = !!(user?.['SMEs']?.length > 0) && showSMEIcon;

  const isWorkstack = variant === 'workstack';
  const roundedHours = user.expertise_hours ? Math.ceil(user.expertise_hours) : null;
  const color = getColorByHours(roundedHours);
  const boxShadow = `0 0 0 2px var(--${color})`;
  return (
    <div className="d-flex flex-column align-items-center text-center" data-fx-name="userAvatar">
      {isWorkstack ? (
        <div
          className={classnames(
            styles.avatar,
            'd-flex align-items-center justify-content-center position-relative'
          )}
          style={{ boxShadow }}
        >
          <Icon className={classnames(styles.workstackIcon, `text-${color}`)}>{user.icon}</Icon>
          <Avatar src={user.avatar} className={styles.miniAvatar} disableLazyLoad={true} />
        </div>
      ) : (
        <div
          className={classnames(showSME && styles.pointer, 'position-relative')}
          onClick={() => handleUserClick(user)}
        >
          {showSME ? (
            <div className={styles.smeRibbonWrapper}>
              <Svg src={ribbon} className={styles.smeRibbon} data-fx-name="ribbonIcon" />
            </div>
          ) : null}
          <Avatar
            src={user.avatar}
            className={styles.avatar}
            style={{ boxShadow }}
            disableLazyLoad={disableLazyLoad}
          />
        </div>
      )}

      {roundedHours ? (
        <div
          className={classnames(
            styles.hoursOverlay,
            'd-flex align-items-center bg-white rounded-pill'
          )}
        >
          <span className={`text-${color} font-16 bold`}>{roundedHours.toLocaleString()}</span>
          <span
            className={classnames(styles.hoursOverlayLabel, 'ml-1 text-left text-uppercase bold')}
            data-fx-name="expertiseHours"
          >
            Expertise
            <br />
            {roundedHours === 1 ? 'Hour' : 'Hours'}
          </span>
        </div>
      ) : null}
      <span className={classnames(styles.name, 'mt-1 font-14')} data-fx-name="userAvatarName">
        {user.displayName}
      </span>
      <span
        className={classnames(styles.title, 'text-gray-550 font-12')}
        data-fx-name="userAvatarTitle"
      >
        {user.title}
      </span>
    </div>
  );
};

export default UserAvatar;
