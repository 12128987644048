import { useEffect } from 'react';
import { store } from '@webfx/core-web';

const useGetScreenSize = () => {
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      store.dispatch.common.setScreenSizeInPx(screenWidth);

      if (screenWidth < 768) {
        return store.dispatch.common.setScreenSize('mobile');
      }
      if (screenWidth >= 768 && screenWidth < 992) {
        return store.dispatch.common.setScreenSize('tablet');
      }

      return store.dispatch.common.setScreenSize('laptop');
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

export default useGetScreenSize;
