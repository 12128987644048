import { useLayoutEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { AuthStore } from './auth';
let layoutCount = 0;

/**
 * This hook is used to ensure that user context switching in
 * other tabes forces all tabs to rehydrate the correct user context.
 *
 * NOTE: this relies on a non-reactive state "layoutCount" to ensure that rehydration
 * only ever runs ones authenication has completed and the user is within the application.
 * Without the < 2 condition this rehydration can run afoul of the login process
 * and cause users to get stuck on the login page.
 */
export function useVisibilityRepersist() {
  const visible = usePageVisibility();
  const currentUser = AuthStore.get.user();

  useLayoutEffect(() => {
    layoutCount++;
    if (layoutCount < 2) {
      return;
    }

    if (visible && currentUser) {
      AuthStore.store.persist.rehydrate();
    }

    // resets after logout
    if (!currentUser) {
      layoutCount = 0;
    }
  }, [visible]);
}
