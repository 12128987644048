import { useStoreState } from 'easy-peasy';

/**
 * Returns the screen width in pixels
 *
 * @returns number
 */
export default function useScreenSize() {
  return useStoreState((state) => state.common.screenSize);
}
