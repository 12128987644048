import useRouter from './useRouter';

/**
 * Hook to determine if the current route should use the full page layout.
 * @returns {boolean}
 */
export default function useFullPageLayout() {
  const { active } = useRouter();
  if (active === 'mcfxReportAppendix') {
    return true;
  }
  return false;
}
