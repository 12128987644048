import React from 'react';
import { Icon } from '@webfx/core-web';
import classnames from 'classnames';
import styles from './Toggle.module.css';

const Toggle = React.forwardRef(({ children, ...passThroughProps }, ref) => {
  return (
    <div
      ref={ref}
      {...passThroughProps}
      className={classnames(
        'bg-white d-flex align-items-center justify-content-between',
        styles.wrapper
      )}
    >
      <div className={styles.children}>{children}</div>
      <Icon className="text-gray-400 font-24 ml-2">keyboard_arrow_down</Icon>
    </div>
  );
});

export default Toggle;
