import React from 'react';
import { SidePanel, Icon } from '@webfx/web-ui';

import { Formik, Form } from 'formik';

import classNames from 'classnames';
import styles from './FilterPanel.module.css';

const FilterPanel = ({
  open = false,
  onClose = () => {},
  setFilters = () => {},
  initialValues = {},
  initialTouched = {},
  children,
  onClear = () => {},
}) => {
  return (
    <SidePanel
      open={open}
      onClose={onClose}
      className={classNames('d-flex flex-column', styles.container)}
      data-fx-name="flyoutPanel"
    >
      <h4 className="flex-none mb-4" data-fx-name="filtersLabel">
        Filters
      </h4>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        initialTouched={initialTouched}
        onSubmit={(values) => {
          setFilters(values);
          onClose();
        }}
      >
        {(formik) => (
          <Form className="d-flex flex-column flex-fill overflow-auto">
            <div className="flex-fill overflow-auto">{children}</div>
            <div className="d-flex flex-row justify-content-between flex-none border-top pt-4">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  onClear(formik);
                }}
                data-fx-name="clearAllButton"
              >
                <Icon className="mr-1" outlined>
                  filter_alt_off
                </Icon>{' '}
                Clear All
              </button>
              <button type="submit" className="btn btn-primary" data-fx-name="applyFiltersButton">
                <Icon outlined>filter_alt</Icon> Apply Filters
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </SidePanel>
  );
};

export default FilterPanel;
