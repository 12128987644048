import React from 'react';
import classnames from 'classnames';

import styles from './Hero.module.css';

const Hero = ({
  backgroundImage,
  backgroundPosition = 'top right',
  backgroundSize = '500px auto',
  children,
  className,
  ...passThroughProps
}) => {
  const bgStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${backgroundSize}`,
    backgroundPosition: `${backgroundPosition}`,
  };

  return (
    <div
      className={classnames('p-5', styles.container, className)}
      style={bgStyle}
      {...passThroughProps}
    >
      {children}
    </div>
  );
};

export default Hero;
