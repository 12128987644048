import ReactQuill from 'react-quill';
import classnames from 'classnames';

import styles from './HtmlInput.module.css';

/**
 * Renders a ReactQuill component for editing a message.
 * Doc for toolbar customization https://github.com/zenoamaro/react-quill?tab=readme-ov-file#custom-toolbar
 *
 * @param params
 * @param params.reactQuillRef
 * @param params.field
 * @param params.form
 * @param params.height
 * @param params.formats
 */
const HtmlInput = ({
  reactQuillRef,
  field,
  form,
  height = 'sm', // Set the default height of the Editor container to small
  formats, // Editor custom formats
  modules, // Editor custom modules
  ...passThroughProps
}) => {
  return (
    <ReactQuill
      value={field.value ?? ''}
      {...(formats && { formats })}
      {...(modules && { modules: { toolbar: [...modules] } })} // To customize the toolbar
      onChange={(value) => form.setFieldValue(field.name, value)}
      ref={reactQuillRef}
      className={classnames(styles.container, styles[`container-${height}`])}
      {...passThroughProps}
    />
  );
};

export default HtmlInput;
