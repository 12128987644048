import classnames from 'classnames';
import { Html, Icon, Image } from '@webfx/core-web';
import { phoneNumber } from '@webfx/formatting';
import mapLeadershipColors from '../../../utils/map-leadership-colors';
import mapLeadershipTitles from '../../../utils/map-leadership-titles';

import styles from './LeaderCard.module.css';

const LeaderCard = ({ industry, leader }) => {
  if (!leader?.name) {
    return null;
  }
  const APP_URL = process.env.FX_APP_URL ? process.env.FX_APP_URL : process.env.POI_APP_URL;

  const getAvatar = (avatar) => {
    if (avatar?.includes('/uploads')) {
      return avatar?.replace('https://app.webfx.com/uploads/', `${APP_URL}/uploads/`);
    } else {
      return avatar;
    }
  };

  const cardStyle = mapLeadershipColors(leader.title);

  return (
    <div
      className={classnames('d-flex flex-column position-relative align-items-center', styles.card)}
    >
      <div
        className={classnames('d-flex position-absolute justify-content-center', styles.imageDiv)}
        style={{ border: `8px solid var(--${cardStyle.imageColor})` }}
      >
        <Image
          src={getAvatar(leader.avatar) ?? 'https://app.webfx.com/assets/images/avatar.png'}
          className={classnames('position-relative', styles.image)}
        />
      </div>

      <div className={styles.cardHeader} style={{ background: cardStyle.backgroundColor }}></div>
      <div className={classnames('d-flex flex-column bg-white flex-fill', styles.cardBody)}>
        <div className="d-flex flex-column">
          <span className="font-14 text-gray-700 bold">{leader.name}</span>
          <span className="font-12 text-gray-600 bold">
            {mapLeadershipTitles({ industry, title: leader.title })}
          </span>
        </div>
        {leader.title === 'VP of Customer Success' ? (
          <div className="mt-2 d-flex flex-column">
            {leader.phone ? (
              <div className="d-flex align-items-center font-14">
                <Icon className={classnames('bg-orange-03 text-white p-1', styles.icon)}>
                  phone_in_talk
                </Icon>
                <span className="ml-2 font-13 semibold text-gray-700">
                  {phoneNumber(leader.phone)}
                </span>
              </div>
            ) : null}
            {leader.email ? (
              <div className="d-flex align-items-center font-14 mt-1">
                <Icon className={classnames('bg-orange-03 text-white p-1', styles.icon)}>mail</Icon>
                <span className="ml-2 font-13 semibold text-gray-700">{leader.email}</span>
              </div>
            ) : null}
          </div>
        ) : null}
        {leader.description ? (
          <div className="mt-3 text-gray-600">
            <Html className="font-12" str={leader.description} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeaderCard;
