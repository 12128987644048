import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import deepmerge from 'deepmerge';
import qs from 'qs';
import { castObjectDeep } from '@webfx/utils';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import { isEmpty } from 'lodash';

/**
 * Helper function to cast standard arrays to mongo $in
 * object syntax
 *
 * @param {object} obj
 * @returns {object}
 */
export const transformToFeathers = (obj) => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  const clone = cloneDeep(obj);

  Object.entries(clone).forEach(([key, value]) => {
    if (Array.isArray(value) && key !== '$in' && key !== '$exists') {
      if (value.length === 1 && key !== 'excludes') {
        clone[key] = value[0];
        return;
      }
      clone[key] = { $in: value };
      return;
    }
    if (isPlainObject(value)) {
      clone[key] = transformToFeathers(value);
    }
  });

  return clone;
};

// eslint-disable-next-line no-unused-vars -- exclude next line (options)
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

// replaces multiple keys with the last key
export function replaceLastKey(value, key) {
  if (value && typeof value === 'object' && key in value && typeof value[key] === 'object') {
    const sortKeys = Object.keys(value[key]);
    if (sortKeys.length > 1) {
      const lastSortKey = sortKeys.pop();
      value[key] = { [lastSortKey]: value[key][lastSortKey] };
    }
  }
  return value;
}

function checkKeysExist(obj, keysToCheck) {
  const results = {};
  keysToCheck.forEach((key) => {
    results[key] = {
      hasOwnProperty: has(obj, key),
    };
  });
  return results;
}

/**
 * utility for setting and using filters with URL params
 *
 * @param {object} options
 * @param {object} options.defaults default values to carry to the query
 * @param {Array} options.hiddenKeys any keys we want to exclude from the url params
 * @param {Array} options.replaceKeys any keys we want to replace from the url params, rather than merging it with the existing value eg: ["$sort"]
 * @returns {object}
 */
export default function useParamFilters({
  defaults = {},
  hiddenKeys,
  replaceKeys,
  transformKeys = {},
} = {}) {
  const location = useLocation();
  const history = useHistory();

  const [[filters, query], setFilters] = React.useState(() => {
    const nextState = castObjectDeep(
      deepmerge(
        defaults,
        qs.parse(location.search, {
          ignoreQueryPrefix: true,
          arrayLimit: 1000,
        }),
        { arrayMerge: overwriteMerge }
      )
    );
    return [nextState, transformToFeathers(nextState, transformKeys)];
  });

  const filterKeys = (prefix, value) => {
    if (Array.isArray(hiddenKeys) && hiddenKeys.includes(prefix)) {
      return;
    }

    if (prefix === '$skip' && value === 0) {
      return;
    }

    if (value === '') {
      return;
    }

    if (!isEmpty(replaceKeys) && checkKeysExist(value, replaceKeys)) {
      let replacedValue = '';
      replaceKeys.forEach((key) => {
        replacedValue = replaceLastKey(value, key);
      });
      return replacedValue;
    }

    return value;
  };

  const setFiltersTo = (f) => {
    if (JSON.stringify(f) === JSON.stringify(defaults)) {
      history.push({ search: '' });
      return;
    }

    history.push({
      search: qs.stringify(f, {
        filter: filterKeys,
        encodeValuesOnly: true,
      }),
    });
  };

  const setInFilters = (f, arrayFormat = 'repeat') => {
    const toSet = deepmerge(
      castObjectDeep(
        qs.parse(location.search, {
          ignoreQueryPrefix: true,
          arrayLimit: 1000,
        })
      ),
      f,
      { arrayMerge: overwriteMerge }
    );

    history.push({
      search: qs.stringify(toSet, {
        filter: filterKeys,
        encodeValuesOnly: true,
        arrayFormat,
      }),
    });
  };

  React.useEffect(() => {
    if (!location.search && JSON.stringify(filters) !== JSON.stringify(defaults)) {
      setFilters([defaults, transformToFeathers(defaults, transformKeys)]);
      return;
    }

    if (location.search) {
      const nextState = castObjectDeep(
        deepmerge(
          defaults,
          qs.parse(location.search, {
            ignoreQueryPrefix: true,
            arrayLimit: 1000,
          }),
          { arrayMerge: overwriteMerge }
        )
      );

      setFilters([nextState, transformToFeathers(nextState, transformKeys)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- exhaustive
  }, [location.search, JSON.stringify(defaults)]);

  return {
    filters,
    query,
    setFiltersTo,
    setInFilters,
  };
}
