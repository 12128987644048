import { useAuth } from '@webfx/web-hooks';
import { useCallback } from 'react';
import useActiveSite from './useActiveSite';

/**
 * Create a route to the legacy application
 * @returns {Function}
 */
export default function useLegacyRoute() {
  const auth = useAuth();
  const site = useActiveSite();
  return useCallback(
    (path) => {
      let route = path || '';
      if (route && route.includes(':siteId')) {
        route = route.replace(':siteId', parseInt(site.siteId));
      }
      return `${process.env.POI_APP_LEGACY_DOMAIN}/${route}?fxt=${auth.user.apiKey}&fxs=${site.siteId}`;
    },
    [auth, site]
  );
}
