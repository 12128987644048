import { useEffect } from 'react';

/**
 * Handles a click event when mouse is outside of a target point
 *
 * @param {object} ref - reference point of mouse
 * @param {object} onClick
 */
function useClickOutside(ref, onClick) {
  const handleClickOutside = (event) => {
    if (!ref?.current) {
      return;
    }
    if (!ref.current.contains(event.target)) {
      if (onClick) {
        onClick();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useClickOutside;
