import qs from 'qs';
import { DateTime } from 'luxon';

export const quarterList = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];

const quarterMonthsArray = [
  ['01', '02', '03'],
  ['04', '05', '06'],
  ['07', '08', '09'],
  ['10', '11', '12'],
];

const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getSelectedQuarter = ({ quarter, quarterList }) => {
  const monthAndYear = quarter[0].split('-');

  let quarterIndex;
  quarterMonthsArray.map((quarterList, index) => {
    if (quarterList[0] === monthAndYear[0]) {
      quarterIndex = index;
    }
  });
  return {
    quarterIndex,
    month: quarterList[quarterIndex],
    year: parseInt(monthAndYear[1]),
    quarter: quarterIndex + 1,
  };
};

export const getCurrent = (quarters = []) => {
  const now = new Date();
  const quarter = inQuarters(quarters);

  if (quarter) {
    return { quarter, year: now.getFullYear() };
  }

  const lastYearsQuarters = getQuarters(quarters[0].months[0], now.getFullYear() - 1).quarters;

  return { quarter: inQuarters(lastYearsQuarters), year: now.getFullYear() - 1 };
};

const inQuarters = (quarters) => {
  let quarter = null;

  for (const q of quarters) {
    if (
      DateTime.fromISO(DateTime.fromISO(q.monthRange[0])).diffNow('days') < 0 &&
      DateTime.fromISO(
        DateTime.fromISO(getLastDayOfTheMonthByDate(q.monthRange[1])).endOf('day')
      ).diffNow('days') > 0
    ) {
      quarter = q;
      break;
    }
  }
  return quarter;
};

export const getCurrentQuarter = () => {
  const now = new Date();
  const quarter = Math.floor((now.getMonth() + 3) / 3);
  return {
    quarter,
    year: now.getFullYear(),
    months: getQuarterMonthRange(quarter),
  };
};

export const getQuarterMonths = ({ quarter, year } = {}) => {
  const now = new Date();
  const selectedYear = year ? year : now.getFullYear();
  const selectedQuarter = quarter ? parseInt(quarter) : Math.floor((now.getMonth() + 3) / 3);
  const firstMonthOfQuarter = new Date(now.getFullYear(), 3 * selectedQuarter - 3, 1);

  return [
    `${selectedYear}-${(firstMonthOfQuarter.getMonth() + 1).toString().padStart(2, 0)}-01`,
    `${selectedYear}-${(firstMonthOfQuarter.getMonth() + 2).toString().padStart(2, 0)}-01`,
    `${selectedYear}-${(firstMonthOfQuarter.getMonth() + 3).toString().padStart(2, 0)}-01`,
  ];
};

export const getCustomQuarterMonths = (quarter) => {
  const firstMonth = quarter.monthRange[0].slice(5, 7);
  const firstMonthYear = quarter.monthRange[0].slice(0, 4);

  const secondMonth = getNextMonth(firstMonth, firstMonthYear);

  return [quarter.monthRange[0], secondMonth, quarter.monthRange[1]];
};

export const getKanbanMonths = ({ quarter, monthView }) => {
  const months = monthView === 'quarterly' ? 3 : 6;
  const monthRange = [];

  let currentMonth = quarter.monthRange[0].slice(5, 7);
  let currentYear = quarter.monthRange[0].slice(0, 4);

  for (let i = 0; i < months; i++) {
    monthRange.push(`${currentYear}-${currentMonth}-01`);
    const nextDate = getNextMonth(currentMonth, currentYear);
    currentMonth = nextDate.slice(5, 7);
    currentYear = nextDate.slice(0, 4);
  }

  return monthRange;
};

const getNextMonth = (currentMonth, currentYear) => {
  let nextMonth = parseInt(currentMonth, 10) + 1;
  let year = currentYear;

  if (nextMonth > 12) {
    nextMonth = 1;
    year = (parseInt(currentYear, 10) + 1).toString();
  }

  return `${year}-${nextMonth.toString().padStart(2, '0')}-01`;
};

export const getQuarterMonthRange = (quarter = 1) => {
  const now = new Date();
  const firstMonthOfQuarter = new Date(now.getFullYear(), 3 * quarter - 3, 1);
  const lastMonthOfQuarter = new Date(now.getFullYear(), 3 * quarter - 1, 1);

  return `${firstMonthOfQuarter.toLocaleString('en-US', {
    month: 'short',
    timeZone: 'UTC',
  })}-${lastMonthOfQuarter.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' })}`;
};

export const getLastDayOfTheMonthByDate = (date) => {
  const dateArray = date.split('-');
  const endOfMonth = DateTime.local(
    parseInt(dateArray[0]),
    parseInt(dateArray[1]),
    parseInt(dateArray[2])
  )
    .endOf('month')
    .toISODate();
  return endOfMonth;
};

export const getMonth = (month = null, type = 'long') => {
  if (!month) {
    return null;
  }
  return new Date(month).toLocaleString('en-US', { month: type, timeZone: 'UTC' });
};

export const getSearchQuery = ({ search }) => {
  const query = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return getQuarterMonths({ ...query });
};

export const getYear = (year = null) => {
  if (!year) {
    return null;
  }
  return new Date(year).toLocaleString('en-US', { year: 'long', timeZone: 'UTC' });
};

export const getPreviousOrNextQuarter = ({
  changeBy,
  currentYear,
  currentQuarter,
  quarterSetting,
}) => {
  let newQuarterNumber = Number(currentQuarter.name.slice(1)) + changeBy;
  let newYear = currentYear;

  if (newQuarterNumber > 4) {
    newQuarterNumber = 1;
    newYear = currentYear + changeBy;
  }

  if (newQuarterNumber < 1) {
    newQuarterNumber = 4;
    newYear = currentYear + changeBy;
  }

  const quarters = getQuarters(quarterSetting, newYear).quarters;
  const newQuarter = quarters[newQuarterNumber - 1];

  return {
    newQuarter,
    newYear,
  };
};

export const getQuarters = (quarterSetting, selectedYear = new Date().getFullYear()) => {
  const quarters = [];

  let index = Number(quarterSetting) || 0;
  let currentYear = selectedYear;

  for (let i = 0; i < 4; i++) {
    if (index > 11) {
      index = 0;
      currentYear++;
    }
    const firstMonthValue = (index + 1).toString().padStart(2, 0);
    const firstMonth = `${currentYear}-${firstMonthValue}-01`;
    let label = `${monthLabels[index] ?? ''}${
      selectedYear !== currentYear ? '(' + currentYear.toString().slice(2) + ')' : ''
    }`;

    const lastMonthValue = index + 2 > 11 ? (index + 2) % monthLabels.length : index + 2;
    if (index + 2 > 11) {
      currentYear++;
    }

    const lastMonth = (lastMonthValue + 1).toString().padStart(2, 0);
    label += `-${monthLabels[lastMonthValue] ?? ''}${
      selectedYear !== currentYear ? '(' + currentYear.toString().slice(2) + ')' : ''
    }`;

    const monthRange = [firstMonth, `${currentYear}-${lastMonth}-01`];
    quarters.push({
      label,
      monthRange,
      name: `Q${i + 1}`,
      months: [index, index + 2 > 11 ? 0 : index + 2],
    });
    index = lastMonthValue + 1;
  }
  return { year: selectedYear, quarters };
};

export const rotateOneMonth = (months, changeBy) => {
  const fullMonths = getCustomQuarterMonths(months);

  if (changeBy === 1) {
    const lastMonth = months.monthRange[1].slice(5, 7);
    const lastMonthYear = months.monthRange[1].slice(0, 4);

    const newLastMonth = getNextMonth(lastMonth, lastMonthYear);

    return {
      monthRange: [fullMonths[1], newLastMonth],
    };
  } else {
    const firstMonth = months.monthRange[0].slice(5, 7);
    const firstMonthYear = months.monthRange[0].slice(0, 4);

    const newFirstMonth =
      firstMonth === '01' ? '12' : (Number(firstMonth) - 1).toString().padStart(2, 0);
    const newFirstMonthYear =
      newFirstMonth === '12' ? (Number(firstMonthYear) - 1).toString() : firstMonthYear;

    return {
      monthRange: [`${newFirstMonthYear}-${newFirstMonth}-01`, fullMonths[1]],
    };
  }
};

export const getCutoffYearSettingOffset = (cutoff, quarterSetting) => {
  if (!cutoff) {
    return null;
  }

  const passedCutoffYear = Number(cutoff.slice(0, 4));
  const { quarters } = getQuarters(quarterSetting, passedCutoffYear);

  if (
    DateTime.fromISO(cutoff) >= DateTime.fromISO(quarters[0].monthRange[0]) &&
    DateTime.fromISO(cutoff) <= DateTime.fromISO(quarters[3].monthRange[1])
  ) {
    return passedCutoffYear;
  }
  return passedCutoffYear - 1;
};

export const cutoffMonthQuarter = (cutoff, quarters) => {
  for (let i = 0; i < quarters.length; i++) {
    if (DateTime.fromISO(cutoff) <= DateTime.fromISO(quarters[i].monthRange[1])) {
      return i;
    }
  }
  return null;
};

export const getQuarterFromMonth = (currentMonth, quarterSetting, selectedYear) => {
  const { quarters } = getQuarters(quarterSetting, selectedYear);

  // Otherwise, we get the month range from the quarter where the fuelfx item was created
  const range = quarters?.find((quarter) => {
    return (
      DateTime.fromISO(currentMonth) >= DateTime.fromISO(quarter.monthRange[0]) &&
      DateTime.fromISO(currentMonth) <= DateTime.fromISO(quarter.monthRange[1])
    );
  });

  return range?.monthRange;
};
