import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useAuth } from '@webfx/web-hooks';

export function usePageView() {
  const location = useLocation();
  const history = useHistory();
  const setLocation = useStoreActions((actions) => actions.router.setLocation);
  const activeRoute = useStoreState((state) => state.router.active);
  const auth = useAuth();

  useEffect(() => {
    setLocation(location);
  }, [location.pathname]);

  useEffect(() => {
    if (!activeRoute || !auth.user || !auth?.isGuest) {
      return;
    }

    const routes = auth.user?.roles?.[0].name;
    const matched = routes.split(',').filter((role) => location.pathname.indexOf(role) > -1);

    /**
     * Log out under the following conditions:
     * 1. The user is a guest and the route is unmatched.
     * 2. The user is a guest and the route is inactive. This supports legacy publicToken route assignments.
     */
    if ((!matched || matched.length === 0) && routes !== activeRoute) {
      auth.logout().then(() => {
        history.replace('/signin');
      });
    }
  }, [activeRoute, location.pathname]);
}
