import classnames from 'classnames';
import { Svg } from '@webfx/core-web';
import PartnerLogo from './PartnerLogo';

import { PARTNER_LOGOS } from '../../constants';
import MCFXLogo from '/public/assets/images/contributors/mcfxLogo.svg';

import styles from '../Subheader.module.css';

const PartnershipLogos = ({ className }) => {
  return (
    <>
      <div className={classnames('d-flex my-2 px-3 font-weight-semibold', className)}>
        <Svg svgStyle={{ height: 20 }} src={MCFXLogo} />
        <span className="ml-3">Partnerships:</span>
      </div>

      <div
        className={classnames(
          'd-flex justify-content-between align-items-center px-3',
          styles.logoContainer
        )}
      >
        {PARTNER_LOGOS.map((logo, idx) => (
          <PartnerLogo
            key={`partner-logo-${idx}`}
            src={logo.src}
            alt={logo.alt}
            className={logo.className}
            dataFxName={logo.dataFxName}
          />
        ))}
      </div>
    </>
  );
};

export default PartnershipLogos;
