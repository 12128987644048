import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useAuth } from '@webfx/web-hooks';
import { api } from '@webfx/core-web';

/**
 * Return what the current in use app is.
 *
 * @returns {string}
 */
export default function useApp() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const queryEnabled = user?.userId && user?.products?.[0].matchPath === null;
  const userApps = api.useQuery(
    ['users', user?.userId, { $select: ['userId'], $join: ['products'] }],
    {
      enabled: queryEnabled,
      cacheTime: 60 * 1000,
    }
  );

  const apps = user?.products?.[0].matchPath ? user?.products : userApps?.data?.products ?? [];
  const isLoading = queryEnabled && userApps.isLoading;

  const matches = apps.reduce((matches, curr) => {
    if (curr.matchPath && matchPath(pathname, curr.matchPath)) {
      matches.push(curr);
    }
    return matches;
  }, []);

  matches.sort((a, b) => {
    const aSegments = a.matchPath.split('/').length;
    const bSegments = b.matchPath.split('/').length;
    return bSegments - aSegments;
  });

  const activeApp = matches?.[0]?.productId;

  return useMemo(() => [activeApp, isLoading], [activeApp, isLoading]);
}
