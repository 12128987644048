import { api } from '@webfx/core-web';
import useAuth from './useAuth';

/**
 * This hook fetches IAM user data based on the provided query.
 * If no userId is provided in the query, it defaults to the current authenticated user's ID.
 *
 * @example // fetch any specific user.
 * const { data, error, isLoading } = useIamUser({ userId: 12345 });
 * @example // Fetches data for the current authenticated user.
 * const { data, error, isLoading } = useIamUser();
 * @example//  Fetches data with addition query params.
 * const { data, error, isLoading } = useIamUser({ $join:['teams'] });
 *
 * @param {Object} query - Query parameters to filter the IAM user data.
 * @param {string} [query.userId] - The ID of the user to fetch data for. Defaults to the current authenticated user's ID.
 * @returns {Object} - The result of the API query.
 */
export function useIamUser(query = {}) {
  const { userId: currentUserId } = useAuth();
  const { userId, ...passThroughQuery } = query;
  const targetUserId = userId || currentUserId;

  const key = ['iam/users', targetUserId];

  if (Object.keys(passThroughQuery).length) {
    key.push(passThroughQuery);
  }

  return api.useQuery(key, {
    enabled: !!targetUserId,
  });
}
