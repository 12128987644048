import { useState, useMemo } from 'react';
import { api } from '@webfx/core-web';
import { Combobox } from '@webfx/web-ui';

/**
 * A component that renders a company selector dropdown.
 *
 * @component
 * @param {object} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const CompanySelector = (props) => {
  const [search, setSearch] = useState('');
  const { data, isLoading, isFetchingNextPage, fetchNextPage } = api.useInfiniteQuery([
    'companies',
    {
      name: { ...(search ? { $like: `%${search}%` } : { $notLike: 'Company % - %' }) },
      $or: [{ active: true }, { internal: true }],
      $sort: { name: 1 },
      $limit: 500,
    },
  ]);

  const options = useMemo(() => {
    return (data?.data ?? []).map((c) => ({
      label: c.name,
      value: c.companyId,
    }));
  }, [data]);

  const virtualize = useMemo(() => {
    return {
      style: { height: 200 },
      onRangeChange: ({ stopIndex }) => {
        if (stopIndex > data?.data.length - 50 && !isFetchingNextPage && data?.hasMore) {
          fetchNextPage();
        }
      },
    };
  }, [data, isFetchingNextPage, fetchNextPage]);

  return (
    <Combobox
      isLoading={isLoading}
      options={options}
      virtualize={virtualize}
      placeholder="Select a Company"
      onSearchChange={(event) => {
        setSearch(event.target.value);
      }}
      {...props}
    />
  );
};
