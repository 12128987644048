import classnames from 'classnames';
import { Html, Icon, Image } from '@webfx/core-web';
import styles from './KeyLeadershipV1.module.css';

const KeyLeadership = ({ leaders }) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-2 font-20">
        <Icon className="text-blue-vivid-200">flag</Icon>
        <span className="ml-2 bold">Key Leadership</span>
      </div>
      <div className={styles.leadersGrid}>
        {leaders.map((leader, idx) => (
          <div className="d-flex flex-column" key={`leader-${idx}`}>
            <Image src={leader.avatar} srcSet={true} className={styles.image} />
            <div
              className={classnames(
                styles.info,
                `d-flex flex-column flex-grow-1 align-items-center text-center bg-${leader.color} p-3`
              )}
            >
              <span className={classnames(styles.name, 'bold')}>{leader.name}</span>
              <span className={classnames(styles.title, 'font-12')}>{leader.title}</span>
              {leader.phone || leader.email ? (
                <div className="mt-2">
                  {leader.phone ? (
                    <div className="d-flex align-items-center font-14">
                      <Icon>phone_in_talk</Icon>{' '}
                      <span className="ml-2 font-12">{leader.phone}</span>
                    </div>
                  ) : null}
                  {leader.email ? (
                    <div className="d-flex align-items-center font-14">
                      <Icon>email</Icon> <span className="ml-2 font-12">{leader.email}</span>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {leader.description ? (
                <div className="mt-3">
                  <Html className="text-left font-12" str={leader.description} />
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyLeadership;
