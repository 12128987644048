import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useField } from 'formik';
import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import Icon from '../Icon';
import _ from 'lodash';
import styles from './Facet.module.css';

export default function Facet({
  label,
  icon,
  options,
  defaults,
  defaultOpen = false,
  className,
  dataFxName,
  ...props
}) {
  const [field, meta, helpers] = useField(props);
  const allKey = meta.value?.length === 1 ? meta.value[0] : null;
  const [hoverState, setHoverState] = useState(null);

  const reset = () => {
    helpers.setTouched(false);
    helpers.setValue(defaults);
  };

  const onOptionClick = (option) => () => {
    helpers.setTouched(true);

    if (option.value === allKey) {
      reset();
      return;
    }
    helpers.setValue([option.value]);
    helpers.setTouched(true);
  };

  const onInputChange = (event) => {
    if (event.target.value === allKey) {
      reset();
      return;
    }

    const inputValue = event.target.value;

    const idx = meta.value?.indexOf(inputValue);

    let optionValue = options.map((v) => v.value).filter((v) => v !== inputValue);

    if (idx === -1) {
      optionValue = meta.value.length === 0 ? optionValue : [...meta.value, inputValue];
      helpers.setValue(optionValue);
    } else {
      helpers.setValue(meta.value.filter((v) => v !== inputValue));
    }

    helpers.setTouched(true);
  };

  if (!options?.length) {
    console.warn(`No options provided for Facet: ${label}`);
    return null;
  }

  return (
    <Disclosure
      as="div"
      defaultOpen={defaultOpen}
      className={classnames(styles.container, className)}
      data-fx-name={_.camelCase(label)}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classnames(['d-flex align-items-center', styles.toggle])}
            data-fx-name="toggleButton"
          >
            <>
              {icon && typeof icon === 'string' && (
                <Icon className={classnames([styles.icon, 'mr-2'])}>{icon}</Icon>
              )}
              {icon && typeof icon !== 'string' && icon}
            </>{' '}
            <span className={classnames([styles.label, 'flex-fill'])} data-fx-name="menuLabel">
              {label}
            </span>
            <Icon
              className={classnames({
                'transform rotate-90': open,
                [styles.indicator]: true,
              })}
              data-fx-name="collapseIndicator"
            >
              chevron_right
            </Icon>
          </Disclosure.Button>
          {!!meta.touched && (
            <OverlayTrigger overlay={<Tooltip>Reset</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <button
                  ref={ref}
                  type="button"
                  className={classnames([
                    'd-flex align-items-center justify-content-center',
                    styles.reset,
                  ])}
                  onClick={reset}
                  {...triggerHandler}
                >
                  <Icon className="font-18" outlined>
                    filter_alt_off
                  </Icon>
                </button>
              )}
            </OverlayTrigger>
          )}
          <Disclosure.Panel
            as="ul"
            className={classnames({
              [styles.open]: open,
              [styles.options]: true,
            })}
          >
            {options.map((opt) => (
              <li
                key={opt.value}
                className={classnames([styles.option, opt?.styles, 'd-flex align-items-center'])}
              >
                <input
                  type="checkbox"
                  id={opt.value}
                  name={field.name}
                  value={opt.value}
                  className={classnames([styles.optionCheckbox, 'mr-2'])}
                  checked={meta.value?.includes?.(opt.value) || meta.value?.length === 0}
                  onChange={onInputChange}
                  onMouseEnter={() => setHoverState(opt.value)}
                  onMouseLeave={() => setHoverState(null)}
                  data-fx-name="checkbox"
                />
                <button
                  type="button"
                  className={classnames({
                    'flex-fill': true,
                    [styles.optionLabel]: true,
                    [styles.togglesAll]: allKey === opt.value,
                    [styles.showToggle]: hoverState === opt.value,
                  })}
                  onClick={onOptionClick(opt)}
                  data-fx-name={
                    opt.dataFxName
                      ? `${_.camelCase(opt.dataFxName)}OptionLabel`
                      : `${_.camelCase(opt.value)}OptionLabel`
                  }
                >
                  {opt.label}
                </button>
              </li>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

Facet.prototype = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }))
    .isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultOpen: PropTypes.bool,
};
