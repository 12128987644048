import { Avatar, Icon } from '@webfx/core-web';
import { DateTime } from 'luxon';
import classnames from 'classnames';
import styles from './UserProfileSnippet.module.css';

/**
 * Renders a user profile snippet.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.user - The user object containing user information.
 * @param {boolean} [props.showCompanyInfo=false] - Determines whether to show company information.
 * @returns {JSX.Element|null} The rendered user profile snippet.
 */
export function UserProfileSnippet({ user, showCompanyInfo = false }) {
  if (!user) {
    return null;
  }
  const email = user.emails?.find?.((e) => e.type === 'work')?.email ?? user.email;

  return (
    <>
      <div className="d-flex align-items-center font-13">
        <Avatar src={user.avatarUrl} />
        <div className="flex-fill ml-3">
          <h3 className="h6 mb-1">
            {user.firstName} {user.lastName}
          </h3>
          {user.title ? <p className="text-muted mb-1">{user.title}</p> : null}
          {email ? (
            <p className="mb-0">
              <a href={`mailto: ${email}`}>{email}</a>
            </p>
          ) : null}
          {user.officeNumber || user.mobileNumber ? (
            <div className="d-flex flex-wrap">
              {user.officeNumber ? (
                <p className="mb-0 mr-2">
                  <span className="font-weight-bold">W:</span> {user.officeNumber}{' '}
                  {user.ext ? ` ext ${user.ext}` : null}
                </p>
              ) : null}
              {user.mobileNumber ? (
                <p className="mb-0">
                  <span className="font-weight-bold">M:</span> {user.mobileNumber}
                </p>
              ) : null}
            </div>
          ) : null}
          {user.lastActiveAt ? (
            <p className="text-muted font-11 mt-1 mb-0">
              Last Login: {DateTime.fromISO(user.lastActiveAt).toFormat('LLL dd, yyyy')}
            </p>
          ) : null}
          {user.lastActivity ? (
            <p className="text-muted font-11 mt-1 mb-0">
              Last Activity: {DateTime.fromISO(user.lastActivity).toFormat('LLL dd, yyyy')}
            </p>
          ) : null}
        </div>
      </div>

      {showCompanyInfo ? (
        <ul
          className={classnames(
            'row list-unstyled font-15 border-top pt-2 mt-3',
            styles.companyInfo
          )}
        >
          <li
            className={classnames({
              'col-6': true,
              'text-gray-400': !user?.company?.primaryContact,
            })}
          >
            <Icon
              className={classnames({
                'font-18': true,
                'text-blue-vivid-400': !!user?.company?.primaryContact,
              })}
              outlined={!user?.company?.primaryContact}
            >
              check_circle
            </Icon>{' '}
            Primary Contact
          </li>
          <li
            className={classnames({
              'col-6': true,
              'text-gray-400': !user?.company?.interactiveContact,
            })}
          >
            <Icon
              className={classnames({
                'font-18': true,
                'text-blue-vivid-400': !!user?.company?.interactiveContact,
              })}
              outlined={!user?.company?.interactiveContact}
            >
              check_circle
            </Icon>{' '}
            Interactive Contact
          </li>
          <li
            className={classnames({
              'col-6': true,
              'text-gray-400': !user?.company?.executiveContact,
            })}
          >
            <Icon
              className={classnames({
                'font-18': true,
                'text-blue-vivid-400': !!user?.company?.executiveContact,
              })}
              outlined={!user?.company?.executiveContact}
            >
              check_circle
            </Icon>{' '}
            Executive Contact
          </li>
          <li
            className={classnames({
              'col-6': true,
              'text-gray-400': !user?.company?.billingContact,
            })}
          >
            <Icon
              className={classnames({
                'font-18': true,
                'text-blue-vivid-400': !!user?.company?.billingContact,
              })}
              outlined={!user?.company?.billingContact}
            >
              check_circle
            </Icon>{' '}
            Billing Contact
          </li>
          <li
            className={classnames({
              'col-6': true,
              'text-gray-400': !user?.company?.externalContact,
            })}
          >
            <Icon
              className={classnames({
                'font-18': true,
                'text-blue-vivid-400': !!user?.company?.externalContact,
              })}
              outlined={!user?.company?.externalContact}
            >
              check_circle
            </Icon>{' '}
            External Contact
          </li>
        </ul>
      ) : null}
    </>
  );
}
