import { api } from '@webfx/core-web';

/**
 * Hook to get contributors for a company
 *
 * @param {number} companyId
 * @returns {object}
 */
function useContributors(companyId) {
  return api.useQuery(['contributors', Number(companyId)], { enabled: !!companyId });
}

export default useContributors;
