import React from 'react';
import { useResize } from '@webfx/web-context';
import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './DropdownItem.module.css';

const DropdownItem = React.forwardRef(
  ({ children, data, index, style, ...passThroughProps }, ref) => {
    const { loginAsUser } = useAuth();

    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const { setSize } = useResize();

    const encryptedUser = data.sites.some((site) => site.encryption);

    React.useEffect(() => {
      if (resolvedRef.current) {
        setSize({
          id: index,
          size: resolvedRef.current.getBoundingClientRect().height,
        });
      }
    }, [index, resolvedRef, setSize]);

    return (
      <div style={style}>
        <Link
          role="button"
          {...passThroughProps}
          key={`user-${data.userId}`}
          to={window.location.pathname}
          ref={resolvedRef}
          data-fx-name="menuItems"
          className={classnames('d-block py-2 px-3 border-bottom', styles.link)}
          onClick={async () => {
            if (!encryptedUser) {
              await loginAsUser(data.tokens?.[0]?.token ?? data.apiKey);
            }
          }}
        >
          <h4 className={styles.title}>{children}</h4>
          <p className={styles.subtitle}>{data.email.replace(/^http(s)?:\/\/(www\.)?/, '')} </p>
        </Link>
      </div>
    );
  }
);

export default DropdownItem;
