import { api } from '@webfx/core-web';

/**
 * Hook to get company industry stats.
 *
 * @param {string} companyId
 * @param {object} industry
 * @returns {object}
 */
function useIndustryStats(companyId, industry) {
  const industryId = industry?.industryId;
  return api.useQuery(
    [
      '/industry-stats',
      {
        query: {
          companyId,
          industryId,
        },
      },
    ],
    {
      enabled: !!companyId,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    }
  );
}

export default useIndustryStats;
