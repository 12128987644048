import classnames from 'classnames';
import { Image } from '@webfx/core-web';

import styles from './TitleBar.module.css';
import CompanyStats from './CompanyStats';
const assetFolder = '/assets/images/contributors';

const TitleBar = ({ companyId }) => {
  return (
    <div className={classnames(styles.wrapper, 'd-flex bg-white')}>
      <div className="w-100 mr-3">
        <div className="d-flex flex-wrap mb-3 align-items-center justify-content-center justify-content-lg-start text-center text-lg-left">
          <h2 className="mb-0 font-28 bold" data-fx-name="contributorsHeader">
            Marketplace Leadership Expertise
          </h2>
        </div>
        <CompanyStats companyId={companyId} />
      </div>
      <div className="ml-auto d-lg-flex d-none align-items-center flex-shrink-0">
        <div className="p-2 bg-blue-001">
          <div className="d-flex align-self-center justify-content-between mb-2">
            <Image
              src={`${assetFolder}/fb-marketingpartner.png`}
              srcSet={true}
              className={styles.partnerLogo}
              data-fx-name="fbLogo"
            />
            <Image
              src={`${assetFolder}/googlecloud-partner.png`}
              srcSet={true}
              className={classnames(styles.partnerLogo, 'ml-2')}
              data-fx-name="googleCloudLogo"
            />
          </div>
          <div className="d-flex align-self-center  justify-content-between">
            <Image
              src={`${assetFolder}/bing-partner.png`}
              srcSet={true}
              className={styles.partnerLogo}
              data-fx-name="bingLogo"
            />
            <Image
              src={`${assetFolder}/google-partner.png`}
              srcSet={true}
              className={classnames(styles.partnerLogo, 'ml-2')}
              data-fx-name="googleLogo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center ml-3">
          <Image src={`${assetFolder}/mcfx-logo.png`} srcSet={true} style={{ height: '25px' }} />
          <span className="mt-n1 font-40 bold">1,000,000+</span>
          <span className="mt-n2 font-14">monthly content scans using:</span>
          <div>
            <Image src={`${assetFolder}/ibm-watson.png`} srcSet={true} style={{ height: '22px' }} />
            <Image
              src={`${assetFolder}/google-machinelearning.png`}
              srcSet={true}
              className="ml-3"
              style={{ height: '32px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
