import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';
// TODO remove this hook its basically a duplicate of useActiveSite
/**
 * Custom hook for retrieving site data.
 *
 * @param {string} siteId - The ID of the site to retrieve data for.
 * @param {object} query - Additional query parameters for fetching site data.
 * @returns {object} - The site data.
 */
const useSite = (siteId, query = {}) => {
  const { siteId: activeSiteId } = useActiveSite();
  const id = siteId ? siteId : activeSiteId;

  const site = api.useQuery(
    [
      'sites',
      id,
      {
        $join: ['company', 'plan'],
        ...query,
      },
    ],
    {
      enabled: !!id,
    }
  );

  return site;
};
export default useSite;
