'use strict';
import { useEffect } from 'react';
import useActiveSite from './useActiveSite';

/**
 * Sets the current html document title
 *
 * @param {*} originalTitle
 * @param {boolean} [options={ appendSiteName: true, prependSiteName: false }]
 */
export default function useDocumentTitle(
  originalTitle,
  options = { appendSiteName: true, prependSiteName: false }
) {
  const site = useActiveSite();
  let title = originalTitle;

  if (options.prependSiteName && site?.name) {
    title = `${site.name} |  ${title}`;
  } else if (options.appendSiteName && site?.name) {
    title = ` ${title} | ${site.name}`;
  }
  useEffect(() => {
    if (title && title !== '' && title !== 'undefined') {
      document.title = title;
    }
  }, [title]);
}
