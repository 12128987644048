import { createColumnHelper } from '@tanstack/react-table';
import numbro from 'numbro';

const columnHelper = createColumnHelper();

export const buildColumn = (columnDef) => {
  /* eslint-disable-next-line no-param-reassign -- Set emptyHeader */
  columnDef.emptyHeader = !columnDef.header;
  if (columnDef.cell) {
    const originalCellFunc = columnDef.cell;
    /* eslint-disable-next-line no-param-reassign -- Format cell */
    columnDef.cell = (props) => {
      return globalFormatCell({ row: props.row, value: originalCellFunc(props), columnDef });
    };
  }
  if (columnDef.columns) {
    // Build group column if there are subcolumns
    const { columns, ...rest } = columnDef;
    return columnHelper.group({
      ...rest,
      columns: columnDef.columns.map(buildColumn),
    });
  } else if (columnDef.accessor) {
    // Build accessor column
    const { accessor, ...rest } = columnDef;
    return columnHelper.accessor(accessor, {
      ...rest,
    });
  }
  // Build display column
  return columnHelper.display({ ...columnDef });
};

export const buildDefaultColumn = () => ({
  cell: ({ getValue, row, column }) => {
    return globalFormatCell({ row, value: getValue(), columnDef: column.columnDef });
  },
});

// ALL cell values go through this, regardless of whether custom cell func is used or not
const globalFormatCell = ({ row, value, columnDef }) => {
  // Let null be null
  if (value === null) {
    return null;
  }
  // Always format numbers
  let formattedVal = value;
  const numVal = Number(value);
  if (!isNaN(numVal)) {
    if (columnDef.format === 'currency') {
      formattedVal = formatCurrency(numVal, columnDef.mantissa);
    } else {
      formattedVal = formatNumber(numVal);
    }
  }
  // Lighten text of children rows if value is just a string
  if (row.depth > 0 && typeof formattedVal === 'string') {
    return <span className="text-gray-600">{formattedVal}</span>;
  }

  return formattedVal;
};

export const formatCurrency = (value, mantissa) => {
  return numbro(value).formatCurrency({
    thousandSeparated: true,
    mantissa: mantissa || 0,
  });
};

export const formatNumber = (value) => {
  return numbro(value).format({ thousandSeparated: true });
};
