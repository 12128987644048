import Select from 'react-select';
import { Controller } from 'react-hook-form';
import styles from './SelectHook.module.css';

/**
 * SelectHook Component
 *
 * A custom component that integrates `react-select` with `react-hook-form`.
 *
 * @component
 * @example
 * const options = [
 *   { label: 'Option 1', value: 'option1' },
 *   { label: 'Option 2', value: 'option2' }
 * ];
 *
 * function MyForm() {
 *   const methods = useForm();
 *   const { control } = methods;
 *
 *   return (
 *     <FormProvider {...methods}>
 *       <form onSubmit={methods.handleSubmit(data => console.log(data))}>
 *         <SelectHook
 *           name="mySelectField"
 *           control={control}
 *           options={options}
 *         />
 *         <button type="submit">Submit</button>
 *       </form>
 *     </FormProvider>
 *   );
 * }
 *
 * @param {Object} props - Component props
 * @param {string} props.name - Name of the field
 * @param {Object} props.control - Control object provided by react-hook-form
 * @param {Object} [props.rest] - Any other props to pass to the react-select component
 * @returns {JSX.Element} The rendered component
 */
function SelectHook({ name, control, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          inputId={rest['data-fx-name']}
          className={styles.select}
          onChange={(selectedOption) => field.onChange(selectedOption)}
          {...field}
          {...rest}
        />
      )}
    />
  );
}

export default SelectHook;
