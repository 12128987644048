import { Svg } from '@webfx/core-web';
import classNames from 'classnames';
import React from 'react';
import nutshellExpIcon from './assets/nutshell.svg';
import nutshellLoadingIndicator from './assets/loadingIndicator.svg';

import SlidesCarousel from './SlidesCarousel';
import styles from './NutshellLoading.module.css';

export function NutshellLoading() {
  return (
    <div
      className="p-3 w-100 justify-content-center align-items-center bg-white"
      style={{ minHeight: '100vh' }}
    >
      <div
        className={classNames('px-5 pt-5 pb-0 mx-auto', styles.loadingContainer)}
        data-fx-name="nutshellLoader"
      >
        <div className="text-center">
          <div className={classNames('mx-auto', styles.loadingIconContainer)}>
            <Svg src={nutshellExpIcon} className={classNames('mx-auto', styles.nutshellExpIcon)} />
            <Svg src={nutshellLoadingIndicator} className={styles.nutshellLoadingIndicator} />
          </div>

          <h2 className={styles.loadingText}>We’re loading your Nutshell experience...</h2>
        </div>
        <div className=" mt-5 p-3">
          <SlidesCarousel />
        </div>
      </div>
    </div>
  );
}
