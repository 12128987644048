import SeoOrganic from '../assets/mroi-channel-icons/seo-organic.svg';
import Influencer from '../assets/mroi-channel-icons/influencer.svg';
import LocalFx from '../assets/mroi-channel-icons/local-fx.svg';
import PaidSearch from '../assets/mroi-channel-icons/paid-search.svg';
import SocialOrganic from '../assets/mroi-channel-icons/social-organic.svg';
import SystemDevice from '../assets/mroi-channel-icons/system-device.svg';
import SocialPaid from '../assets/mroi-channel-icons/social-paid.svg';
import TargetedAds from '../assets/mroi-channel-icons/targeted-ads.svg';
import LeadNurture from '../assets/mroi-channel-icons/email-lead-nurture.svg';
import ReferringWebsites from '../assets/mroi-channel-icons/referring-websites.svg';
import CrmRetargeting from '../assets/mroi-channel-icons/crm-retargeting.svg';
import TraditionalAd from '../assets/mroi-channel-icons/traditional-ad.svg';
import ExternalUncategorized from '../assets/mroi-channel-icons/external-uncategorized.svg';
import Uncategorized from '../assets/mroi-channel-icons/uncategorized.svg';

const channelIcons = {
  SeoOrganic,
  Influencer,
  LocalFx,
  PaidSearch,
  SocialOrganic,
  SystemDevice,
  SocialPaid,
  TargetedAds,
  LeadNurture,
  ReferringWebsites,
  CrmRetargeting,
  TraditionalAd,
  ExternalUncategorized,
  Uncategorized,
};

const ChannelIcon = ({ icon, className, width = '32px', height = '32px' }) => {
  const DynamicIcon = channelIcons[icon];
  if (!DynamicIcon) {
    return null;
  }
  return <DynamicIcon className={className} style={{ width, height }} />;
};

export default ChannelIcon;
