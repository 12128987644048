/**
 * Temp Addition of useLocalStore until easy-peasy 3.4 release
 * https://github.com/ctrlplusb/easy-peasy/issues/451
 */

import { useEffect, useRef, useState } from 'react';
import { useMemoOne } from 'use-memo-one';
import { createStore } from 'easy-peasy';

export default function useLocalStore(modelCreator, dependencies = [], config) {
  const storeRef = useRef();
  const configRef = useRef();

  const store = useMemoOne(() => {
    const previousState = storeRef.current != null ? storeRef.current.getState() : undefined;
    const _store = createStore(modelCreator(previousState), config);
    configRef.current = config;
    storeRef.current = _store;
    return _store;
  }, dependencies);

  const [currentState, setCurrentState] = useState(() => store.getState());

  useEffect(() => {
    return store.subscribe(() => {
      const nextState = store.getState();
      if (currentState !== nextState) {
        setCurrentState(nextState);
      }
    });
  }, [store]);

  return [currentState, store.getActions(), store];
}
