import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useLocalStorage } from '@webfx/web-hooks';
import useMenuVisibility from './useMenuVisibility';

/**
 * Returns visibility rules defined by launch darkly and menu settings
 * @returns {object[]} ldFlags[], menuVisibility[]
 */
export default function useFlags() {
  const { settings: menuVisibility } = useMenuVisibility();
  const ldFlags = useLDFlags();
  const [flagOverrides] = useLocalStorage('flag-overrides', {});
  const useOverrides = ldFlags.flagOverrides;

  return {
    ...ldFlags,
    ...(menuVisibility ?? {}),
    ...(useOverrides && { ...flagOverrides }),
  };
}
