import { useState, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

const useFilters = (initial, tableColumns) => {
  const [filters, setFilters] = useState({});
  const [filterHash, setfilterHash] = useState(uuid());
  const [initialFilters, setInitialFilters] = useState(initial);

  const setFilter = useCallback(
    (payload) => {
      const newFilters = { ...filters };
      if (Array.isArray(payload)) {
        payload.forEach((f) => {
          newFilters[f.key] = f.value;
        });
      } else {
        Object.keys(payload).forEach((key) => {
          newFilters[key] = payload[key];
        });
      }

      setFilters(newFilters);
      setfilterHash(uuid());
    },
    [filters]
  );
  const clearFilter = useCallback(
    (payload) => {
      const newFilters = { ...filters };
      if (Array.isArray(payload)) {
        payload.forEach((key) => {
          delete newFilters[key];
        });
      } else {
        delete newFilters[payload];
      }

      setFilters(newFilters);
      setfilterHash(uuid());
    },
    [filters]
  );
  const resetFilters = useCallback(() => {
    setFilters({});
    setfilterHash(uuid());
  }, []);
  const resetToInitial = useCallback(() => {
    setFilters(initialFilters);
    setfilterHash(uuid());
  }, [initialFilters]);

  useEffect(() => {
    if (tableColumns) {
      setFilter({
        $select: [
          ...tableColumns
            .map((column) => column?.accessor)
            .filter((prop) => typeof prop === 'string'),
          ...(initial?.$select ? initial.$select : []),
        ],
      });
    }
    return;
  }, []);

  return {
    filters,
    setFilters,
    setFilter,
    resetFilters,
    initialFilters,
    setInitialFilters,
    clearFilter,
    resetToInitial,
    filterHash,
  };
};

export default useFilters;
