import React from 'react';
import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Sets menu visibility
 *
 * @returns {*} settings, invalidator, settingId, isLoading, queryKey
 */
const useMenuVisibility = () => {
  const { siteId } = useActiveSite();
  const queryClient = api.useQueryClient();

  const { data, queryKey, isLoading } = api.useQuery(
    [
      `site-settings/${siteId}`,
      {
        field: 'menu-visibility',
      },
    ],
    {
      placeholderData: () => ({}),
      select: (data) => {
        if (Array.isArray(data)) {
          return { settingId: data?.[0]?.siteSettingId, settings: data?.[0]?.value ?? {} };
        }
        return data?.value
          ? { settingId: data.siteSettingId, settings: data.value }
          : { settings: {} };
      },
      enabled: !!siteId,
    }
  );

  const invalidator = React.useCallback(
    () => queryClient.invalidateQueries(queryKey),
    [queryKey, queryClient]
  );

  return {
    settings: data.settings,
    invalidator,
    settingId: data.settingId,
    isLoading,
    queryKey,
  };
};

export default useMenuVisibility;
