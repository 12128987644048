import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Fetches audience data
 *
 * @param {object} filters
 * @returns {*} audience useQuery response
 */
export default function useAudience(filters = {}) {
  const { siteId } = useActiveSite();

  const audience = api.useQuery(
    [
      'identity/audiences',
      {
        query: {
          siteId,
          ...filters,
          $limit: 1,
        },
      },
    ],
    {
      enabled: !!siteId,
      select: (data) => data?.data?.[0] ?? { siteId },
    }
  );

  return audience;
}
