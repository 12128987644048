import { useAuth } from '@webfx/web-hooks';
import { DateTime } from 'luxon';

/**
 *
 * @param date luxon.DateTime
 * @returns {null|luxon.DateTime}
 */
const useDateWithTimezone = (date) => {
  const userTimezone = useAuth().user.timezone;
  const timezone = userTimezone || DateTime.local().toFormat('z');

  if (!date) {
    return null;
  }

  if (timezone) {
    date = date.toUTC().setZone(timezone);
  }

  return date;
};

export default useDateWithTimezone;
