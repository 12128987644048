import { api } from '@webfx/core-web';

/**
 * Hook to get Leadership Members for a company.
 *
 * @param {number} companyId
 * @returns {object}
 */
function useLeadershipMembers(companyId) {
  return api.useQuery(['contributors/leadership', Number(companyId)], { enabled: !!companyId });
}

export default useLeadershipMembers;
