import Google from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/google-analytics.svg';
import Bing from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/bing.svg';
import Facebook from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/facebook.svg';
import LinkedIn from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/linkedin.svg';
import Twitter from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/twitter.svg';
import Pinterest from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/pinterest.svg';
import TikTok from '@webfx/marketingcloud-web/src/assets/logos/budget-booster/tiktok.svg';

const sourceIcons = {
  Google,
  Bing,
  Facebook,
  LinkedIn,
  Twitter,
  Pinterest,
  TikTok,
};

const SourceIcon = ({ icon, className, width = '32px', height = '32px' }) => {
  const DynamicIcon = sourceIcons[icon];
  if (!DynamicIcon) {
    return null;
  }
  return <DynamicIcon className={className} style={{ width, height }} />;
};

export default SourceIcon;
