import { Popover } from '@headlessui/react';
import { Icon } from '@webfx/web-ui';
import classnames from 'classnames';
import { startCase } from 'lodash';
import { forwardRef, useState } from 'react';
import { usePopper } from 'react-popper';
import _ from 'lodash';
import styles from './ManageColumns.module.css';

export default function ManageColumns({ table }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles: popperStyles, attributes: popperAtts } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'offset', options: { offset: [0, 8] } },
      ],
    }
  );

  // keeping the unused variable here to maintain the function length
  // eslint-disable-next-line no-unused-vars -- need to include ref arg so React doesn't complain
  const CloseButton = forwardRef((props, ref) => <Icon {...props}>close</Icon>);

  return (
    <Popover className="ml-auto" data-fx-name="popopFilters">
      <Popover.Button
        ref={setReferenceElement}
        className="d-flex align-items-center border-0 font-14 text-blue-vivid-300 text-hover-blue-vivid-500 bg-transparent"
        data-fx-name="editTable"
      >
        Edit Table
        <Icon className="font-20 ml-2">table_chart</Icon>
      </Popover.Button>
      <Popover.Panel
        ref={setPopperElement}
        className={classnames(styles.panel, 'bg-white shadow-lg rounded-lg p-3')}
        style={popperStyles.popper}
        {...popperAtts.popper}
        data-fx-name="manageTableColumnsPopover"
      >
        <div ref={setArrowElement} className={styles.arrow} />
        <div className="d-flex align-items-center mb-1">
          <div className="bold" data-fx-name="popoverTitle">
            Manage Table Columns
          </div>
          <Popover.Button
            as={CloseButton}
            className="ml-auto font-20 text-primary-light-gray cursor-pointer"
            data-fx-name="closeButton"
          />
        </div>
        {table.getAllLeafColumns().map((column) => {
          const columnName =
            typeof column.columnDef.header === 'string'
              ? column.columnDef.header
              : startCase(column.id);
          const hidingEnabled = column.columnDef.enableHiding !== false;
          if (column.columnDef?.hideFromManageColumns === true) {
            return null;
          }
          return (
            <div key={column.id} className="pt-2">
              <label
                className="d-flex align-items-center rounded mb-0 p-2 font-14 bg-blue-00"
                data-fx-name={`${_.camelCase(columnName)}Checkbox`}
              >
                <input
                  type="checkbox"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                  disabled={!hidingEnabled}
                  className="mr-2"
                />
                {columnName}
                {!hidingEnabled ? <Icon className="ml-auto text-blue-300">lock</Icon> : null}
              </label>
            </div>
          );
        })}
      </Popover.Panel>
    </Popover>
  );
}
