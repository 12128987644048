import { api } from '@webfx/core-web';
import { isEmpty, isString } from 'lodash';
import useParamFilters from './useParamFilters';
const { getTagValues, extractCountryRegion } = require('@webfx/formatting');

/**
 * Queries the idp/company endpoint
 * and query params based on the current route
 * @param {?string} calledBy the product invoking the hook. Used for logging purposes
 * @returns {object} the query object result
 */
export default function useCompanySearch(calledBy) {
  const {
    query: { pagination, filters },
  } = useParamFilters();
  const normalizedFilters = normalizeFilters(filters);

  const companies = api.useQuery(
    [
      'idp',
      'company',
      {
        query: {
          ...normalizedFilters,
          ...normalizePagination(pagination),
        },
        ...(calledBy ? { headers: { 'x-fx-called-by': calledBy } } : {}),
      },
    ],
    {
      enabled: !isEmpty(filters || {}),
      staleTime: Infinity, // disable refetching and only query when the query key changes
    }
  );
  return companies;
}

function normalizePagination(pagination = {}) {
  const $page = pagination?.page ?? 1;
  const $limit = pagination?.limit ?? 10;
  let $sort = pagination?.sortBy || '_score_desc';
  if ($sort) {
    const sortKey = $sort.split('_desc')[0];
    $sort = { [sortKey]: $sort.endsWith('_desc') ? -1 : 1 };
  }
  return {
    ...($sort ? { $sort } : {}),
    ...($page ? { $skip: ($page - 1) * $limit } : {}),
    ...($limit ? { $limit } : {}),
  };
}

const normalizeFilters = (filters = {}) => {
  const parsedFilters = getTagValues(filters);
  const normalizedFilters = { $and: [] };
  const omit = ['excludes'];
  Object.entries(parsedFilters).forEach(([key, value]) => {
    if (omit.includes(key)) {
      return;
    }
    if (parsers[key]) {
      let parsedValue = parsers[key](value, filters);
      if (Array.isArray(parsedValue)) {
        const additionalFilters = parsedValue[1];
        parsedValue = parsedValue[0];
        if (additionalFilters) {
          Object.entries(additionalFilters).forEach(([key, value]) => {
            normalizedFilters[key] = value;
          });
        }
      }
      normalizedFilters.$and.push(parsedValue);
      return;
    }
    if (value.$in && key.split('.').pop() !== 'exact') {
      // if the key ends with exact, we want to perform a term search
      const orFilters = value.$in.map((val) => ({ [key]: val }));
      normalizedFilters.$and.push({ $or: orFilters });
      return;
    }
    normalizedFilters.$and.push({ [key]: value });
  });
  return normalizedFilters;
};

const parsers = {
  location: locationsformatter,
  industries: industriesFormatter,
};

function locationsformatter(value, filters) {
  const { locationRegion = {}, locationCountry = {} } = extractCountryRegion(
    value,
    filters?.excludes?.$in.includes('location') ? '$nin' : '$in'
  );

  // convert to title case because country and region for company search is keyword type in elastic hense case sensitive
  const country = (locationCountry?.$in || []).map((country) => toTitleCase(country));
  const region = (locationRegion?.$in || []).map((region) => {
    return toTitleCase(region.split(',')[0]);
  });

  const locFilters = {
    ...(country?.length ? { country: { $in: country } } : null),
    ...(region?.length ? { region: { $in: region } } : null),
  };
  return locFilters;
}

function industriesFormatter(industries = {}) {
  const hasIndustries = industries.$in?.length > 0;
  if (!hasIndustries) {
    return {};
  }

  return [
    {
      $or: industries.$in.map((industry) => {
        return { NAICSCode: industry };
      }),
    },
    { $prefixNAICS: true },
  ];
}

function toTitleCase(phrase) {
  if (!isString(phrase)) {
    return phrase;
  }
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
