const assetFolder = '/assets/images/contributors';

const PARTNER_LOGOS = [
  {
    src: `${assetFolder}/fb-marketingpartner.png`,
    alt: 'Facebook Marketing Partner',
    dataFxName: 'fbLogo',
  },
  {
    src: `${assetFolder}/bing-partner.png`,
    alt: 'Bing Partner',
    dataFxName: 'bingLogo',
  },
  {
    src: `${assetFolder}/googlecloud-partner.png`,
    alt: 'Google Cloud Partner',
    dataFxName: 'googleCloudLogo',
  },
  {
    src: `${assetFolder}/google-partner.png`,
    alt: 'Google Partner',
    dataFxName: 'googleLogo',
  },
  {
    src: `${assetFolder}/amazon-ads.png`,
    alt: 'Amazon Ads',
    dataFxName: 'amazonAds',
  },
];

const SCAN_LOGOS = [
  {
    src: `${assetFolder}/google-ai.png`,
    alt: 'Google AI',
    dataFxName: 'googleAILogo',
  },
  {
    src: `${assetFolder}/open-ai.png`,
    alt: 'OpenAI',
    dataFxName: 'openAILogo',
  },
  {
    src: `${assetFolder}/ibm-watson.png`,
    alt: 'IBM Watson',
    dataFxName: 'ibmWatsonLogo',
  },
];

module.exports = {
  PARTNER_LOGOS,
  SCAN_LOGOS,
};
