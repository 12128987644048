import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';

import { useClickOutside } from '@webfx/web-hooks';
import Icon from '../Icon';

import styles from './SidePanel.module.css';

function ErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    console.error(error.message);
  }, [error.message]);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <button className="btn btn-primary" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

function SidePanel({
  open = false,
  className,
  children,
  onClose,
  portalNode,
  ...passThroughProps
}) {
  const ref = React.useRef();
  const closePanel = (event) => {
    if (onClose) {
      onClose(event);
    }
  };

  useClickOutside(ref, closePanel);
  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <aside ref={ref} className={classnames([styles.container, className])} {...passThroughProps}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
        <button className={styles.close} onClick={closePanel} data-fx-name="closeFlyoutButton">
          <Icon>close</Icon>
        </button>
        {typeof v === 'function' ? children(closePanel, ref) : children}
      </ErrorBoundary>
    </aside>,
    portalNode || document.body
  );
}

SidePanel.Header = ({ children, ...passThroughProps }) => (
  <header className={styles.header} {...passThroughProps}>
    {children}
  </header>
);

export default SidePanel;
