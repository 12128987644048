import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ReactCalendar from 'react-calendar';
import './DatePicker.style.css';
import { useClickOutside } from '@webfx/web-hooks';
import { toDate, toFormat } from './utils';
import Icon from '../Icon';
import Input from './Input';
import Presets from './Presets';

const defaultPresets = [
  {
    text: 'This Month',
    months: 0,
  },
  {
    text: 'Last Month',
    months: 1,
  },
  {
    text: 'Last 3 Months',
    months: 3,
  },
  {
    text: 'Last 12 Months',
    months: 12,
  },
];

const DatePicker = ({
  className = '',
  id = '',
  view = null,
  defaultView = null,
  showDoubleView = false,
  selectRange = false,
  value = '',
  onDateChange = null,
  closeOnSelect = true,
  shortcuts = false,
  dateFormat = 'MM/DD/YYYY',
  isInputInteractive = false,
  presets = defaultPresets,
  ...props
}) => {
  const ref = React.useRef();
  const [tempDate, setTempDate] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useClickOutside(ref, () => setIsFocused(false));

  useEffect(() => {
    update(value);
  }, [value]);

  useEffect(() => {
    if (selectRange && moment(startDate, dateFormat).isAfter(endDate)) {
      setEndDate('');
    }
  }, [startDate]);

  const update = (value) => {
    if (selectRange) {
      if (value[0] !== startDate || value[1] !== endDate) {
        setStartDate(toFormat(value[0], dateFormat));
        setEndDate(toFormat(value[1], dateFormat));
      }
    } else {
      if (value !== startDate) {
        setStartDate(toFormat(value, dateFormat));
        setEndDate(toFormat(value, dateFormat));
      }
    }
  };

  const handleOnChange = (value) => {
    const startDate = toFormat(value[0], dateFormat);
    const endDate = toFormat(value[1], dateFormat);

    setStartDate(startDate);
    setEndDate(endDate);

    if (onDateChange !== null) {
      if (selectRange) {
        onDateChange([startDate, endDate]);
      } else {
        onDateChange(startDate);
      }
    }
    if (closeOnSelect) {
      setIsFocused(false);
    }
  };

  const getRangeValue = (date1, date2) => {
    const rawNextValue = [date1, date2].sort((a, b) => a - b);
    return [
      moment(rawNextValue[0], dateFormat).startOf('month'),
      moment(rawNextValue[1], dateFormat).endOf('month'),
    ];
  };

  const handleOnClickMonth = (value) => {
    if (selectRange) {
      // Range selection turned on
      if (tempDate === null) {
        setTempDate(value);
        setStartDate(toFormat(value, dateFormat));
        setEndDate(toFormat(value, dateFormat));
      } else {
        const newRange = getRangeValue(tempDate, value);
        handleOnChange(newRange);
        setTempDate(null);
      }
    } else {
      // Range selection turned off
      handleOnChange([value, value]);
    }
  };

  const handleOnInputChange = (name, value) => {
    setIsFocused(false);
    if (selectRange) {
      if (name === 'startDate') {
        handleOnChange([value, endDate]);
      } else {
        handleOnChange([startDate, value]);
      }
    } else {
      handleOnChange([value]);
    }
  };

  const handleMonthsAutoSelect = (startDate, endDate) => {
    handleOnChange([startDate, endDate]);
    if (closeOnSelect) {
      setIsFocused(false);
    }
  };

  return (
    <div
      ref={ref}
      className={classnames([
        'DefaultDatePicker',
        className,
        {
          'DefaultDatePicker--has-shortcuts': shortcuts && selectRange,
          'DefaultDatePicker--doubleView': showDoubleView,
          'DefaultDatePicker--view': typeof view === 'string',
        },
      ])}
    >
      <div
        className="DefaultDatePicker__inputs"
        data-fx-name="datePicker"
        onClick={() => (isInputInteractive ? setIsFocused(!isFocused) : {})}
      >
        <Icon className="DefaultDatePicker__Calendar-icon" onClick={() => setIsFocused(!isFocused)}>
          date_range
        </Icon>

        <Input
          name="startDate"
          placeholder="Start Date"
          value={startDate}
          onChange={handleOnInputChange}
          onFocus={() => setIsFocused(true)}
          dateFormat={dateFormat}
        />
        {selectRange && (
          <>
            —
            <Input
              name="endDate"
              placeholder="End Date"
              value={endDate}
              onChange={handleOnInputChange}
              onFocus={() => setIsFocused(true)}
              dateFormat={dateFormat}
            />
          </>
        )}
      </div>

      <div
        className={classnames(['DefaultDatePicker-Dialog', { 'd-none': !isFocused }])}
        data-fx-name="datePickerDialog"
      >
        {shortcuts && selectRange && (
          <div className="DefaultDatePicker-Shortcuts">
            {presets.map((preset, index) => (
              <Presets
                key={index}
                startDate={startDate}
                endDate={endDate}
                text={preset.text}
                months={preset.months}
                onClick={handleMonthsAutoSelect}
              />
            ))}
          </div>
        )}
        <ReactCalendar
          id={id}
          showDoubleView={showDoubleView}
          value={[toDate(startDate, dateFormat), toDate(endDate, dateFormat)]}
          clearIcon={null}
          onChange={(value) => handleOnChange(value)}
          placeholder="Date"
          showNeighboringMonth={false}
          returnValue="range"
          selectRange={selectRange}
          {...props}
          {...(view !== null && { view })}
          {...(defaultView !== null && { defaultView })}
          {...(view === 'year' && { onClickMonth: (value) => handleOnClickMonth(value) })}
        />
      </div>
    </div>
  );
};

export default DatePicker;
