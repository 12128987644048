import moment from 'moment/moment';

/**
 * Helper to format a date string.
 *
 * @param {string} dateStr Date string to format
 * @param {string} dateFormat Date format, e.g. 'MMM yyyy'
 * @param {*} onInvalid Return value if `dateStr` is invalid.
 * @returns {string} formatted date string
 */
export const toFormat = (dateStr, dateFormat, { onInvalid = '' } = {}) => {
  let formattedDate = onInvalid;
  try {
    const dt = moment(dateStr, dateFormat);
    if (dt.isValid()) {
      formattedDate = dt.format(dateFormat);
    }
  } catch (e) {
    console.error(`Failed to parse date: ${dateStr} | ${e.message || e}`);
  }

  return formattedDate;
};

/**
 * Helper to parse a date string into a JS Date object.
 *
 * @param {string} dateStr Date string to parse
 * @param {string} dateFormat Date format, e.g. 'MMM yyyy'
 * @param {Function} onInvalid If `dateStr` is invalid, then this function's return value will be
 *  returned.
 * @returns {Date} JS Date object.
 */
export const toDate = (dateStr, dateFormat, { onInvalid = () => new Date() } = {}) => {
  const formattedDate = toFormat(dateStr, dateFormat, { onInvalid: null });
  return formattedDate ? moment(formattedDate, dateFormat).toDate() : onInvalid();
};
