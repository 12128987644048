/**
 * An abstracted Switch component wrapper around https://headlessui.com/react/switch
 *
 * Allows for Formik usage (with "field" and "form" props) or manual usage (with "checked" prop).
 *
 * If used with Formik, the field "name" will be passed to the Switch component, which causes it
 * to render a hidden input on the frontend and keep it synced with the switch state.
 */
import { Switch } from '@headlessui/react';
import classnames from 'classnames';
import { Spinner } from 'react-bootstrap';
import { Icon } from '@webfx/core-web';

import styles from './Switch.module.css';

const SwitchComponent = ({
  checked = null, // For manual control
  onChange,
  field = null, // Formik
  form = null, // Formik
  label,
  dataFxName,
  className,
  toggleWithLabel = true, // Clicking on label will toggle switch
  showHandleIcon = true, // Show X or checkmark icon on handle
  showTextStatus = false, // Show "On" or "Off" text status by toggle/label (e.g. Teamwork toggles)
  checkedBackground = 'bg-primary-blue',
  switchBackground = 'bg-gray-550',
  isLoading = false,
  disabled = false,
  locked = false,
  ...passThroughProps
}) => {
  const isFormik = !!field && !!form;
  const checkedVal = isFormik ? field.value : checked;
  const changeHandler = (val) => {
    if (isFormik) {
      form.setFieldValue(field.name, val);
    }
    onChange(val);
  };
  return (
    <Switch.Group {...(dataFxName && { 'data-fx-name': dataFxName })}>
      <div
        className={classnames('d-flex align-items-center font-14', className)}
        {...passThroughProps}
      >
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Switch
            checked={checkedVal}
            onChange={changeHandler}
            className={classnames(
              'position-relative d-inline-flex flex-shrink-0 align-items-center p-0',
              styles.switchBackground,
              checkedVal ? checkedBackground : switchBackground
            )}
            disabled={locked ? true : disabled}
            {...(isFormik && { name: field.name })}
            data-fx-name="switch"
          >
            <span
              className={classnames(
                styles.switchHandle,
                'd-inline-block bg-white rounded-circle',
                checkedVal && styles.checked
              )}
            >
              {showHandleIcon ? (
                <>
                  {checkedVal ? (
                    <Icon className="text-primary-blue" data-fx-name="checkIcon">
                      {locked ? 'lock' : 'check'}
                    </Icon>
                  ) : (
                    <Icon className="text-gray-550" data-fx-name="closeIcon">
                      {locked ? 'lock' : 'close'}
                    </Icon>
                  )}
                </>
              ) : null}
            </span>
          </Switch>
        )}
        {label ? (
          <Switch.Label passive={!toggleWithLabel} className="ml-2 mb-0 semibold">
            {label}
          </Switch.Label>
        ) : null}
        {showTextStatus ? (
          <span
            className={classnames(checkedVal ? 'text-primary-blue bold' : 'text-gray-400', 'ml-2')}
          >
            {checkedVal ? 'On' : 'Off'}
          </span>
        ) : null}
      </div>
    </Switch.Group>
  );
};

export default SwitchComponent;
