import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Icon.module.css';

const Icon = ({
  className,
  as: Component = 'i',
  outlined = false,
  rounded = false,
  children,
  ...passThroughProps
}) => (
  <Component
    className={classNames(className, {
      'material-icons': true, // always true for easy class targeting
      'material-icons-outlined': outlined,
      [styles.rounded]: rounded,
    })}
    aria-hidden
    {...passThroughProps}
  >
    {children}
  </Component>
);

Icon.defaultProps = {
  className: null,
  as: 'i',
  outlined: false,
  rounded: false,
  children: null,
};

Icon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  as: PropTypes.elementType,
  outlined: PropTypes.bool,
  rounded: PropTypes.bool,
};

export default Icon;
