import classnames from 'classnames';
import { DateTime } from 'luxon';
import numbro from 'numbro';
import { Svg } from '@webfx/core-web';

import TouchApp from '../assets/images/svgs/touch-app.svg';
import Phone from '../assets/images/svgs/phone.svg';
import Link from '../assets/images/svgs/link.svg';
import File from '../assets/images/svgs/file.svg';
import User from '../assets/images/svgs/user.svg';

import useIndustryStats from '../hooks/useIndustryStats';
import useCompanyIndustry from '../hooks/useCompanyIndustry';

import styles from './Header.module.css';

const BottomHeader = ({ companyId }) => {
  const { data: industry } = useCompanyIndustry(companyId);
  const { data: stats, isLoading: loadingStats } = useIndustryStats(companyId, industry);
  const currentDate = DateTime.now().toFormat('MM/dd/yy');

  const format = (value, type = '') => {
    if (loadingStats) {
      return '--';
    }
    if (!value) {
      return '0';
    }
    if (type === 'currency') {
      return numbro(value).formatCurrency({
        thousandSeparated: true,
        mantissa: 0,
      });
    }
    return numbro(value).format({ thousandSeparated: true });
  };
  return (
    <div
      className={classnames(
        'd-flex flex-column pt-3 px-4 bg-blue-600 text-white',
        styles.bottomHeader
      )}
    >
      <div className="d-flex justify-content-between">
        <p className="semibold">WebFX-Driven All Time Results for {industry?.industry} clients:</p>
        <p className="m-0 font-12 text-gray-300">Last updated: {currentDate}</p>
      </div>
      <div className="d-flex justify-content-between align-items-center px-3">
        <div className="d-flex flex-column">
          <p className="m-0 semibold">Leads driven</p>
          <div className="d-flex align-items-center mt-1">
            <Svg src={TouchApp} className={styles.iconSvg} />
            <span className="semibold font-24 ml-1">{format(stats?.leads)}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <p className="m-0 semibold">Calls driven</p>
          <div className="d-flex align-items-center mt-1">
            <Svg src={Phone} className={styles.iconSvg} />
            <span className="semibold font-24 ml-1">{format(stats?.calls)}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <p className="m-0 semibold">Revenue driven</p>
          <div className="d-flex align-items-center mt-1">
            <Svg src={Link} className={styles.iconSvg} />
            <span className="semibold font-24 ml-1">{format(stats?.revenue, 'currency')}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <p className="m-0 semibold">Pages created</p>
          <div className="d-flex align-items-center mt-1">
            <Svg src={File} className={styles.iconSvg} />
            <span className="semibold font-24 ml-1">{format(stats?.publishedPages)}</span>
          </div>
        </div>
        <div className="d-flex flex-column">
          <p className="m-0 semibold">Visitors driven</p>
          <div className="d-flex align-items-center mt-1">
            <Svg src={User} className={styles.iconSvg} />
            <span className="semibold font-24 ml-1">{format(stats?.visits)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
