import { useRef } from 'react';
import isEqual from 'react-fast-compare';

/**
 * Memoize a result using deep equality.
 * This hook should be used sparingly, after careful consideration.
 * What's faster than a really fast deep comparison? No deep comparison at all.
 *
 * More context from original:
 * Memoize a result using deep equality. This hook has two advantages over
 * React.useMemo: it uses deep equality to compare memo keys, and it guarantees
 * that the memo function will only be called if the keys are unequal.
 * React.useMemo cannot be relied on to do this, since it is only a performance
 * optimization (see https://reactjs.org/docs/hooks-reference.html#usememo).
 *
 * @param {Function} memoFn memo function
 * @param {object[]} deps dependencies
 * @returns {*} ref.current.value
 */
export default function useDeepMemo(memoFn, deps) {
  const ref = useRef();
  if (!ref.current || !isEqual(deps, ref?.current?.deps)) {
    ref.current = { deps, value: memoFn() };
  }

  return ref.current.value;
}
