/**
 * An abstracted Tab componenet wrapper around
 * https://headlessui.dev/react/tabs
 */
import { Tab as HeadlessTab } from '@headlessui/react';
import Tab from './Tab';

Tab.Group = HeadlessTab.Group;
Tab.List = HeadlessTab.List;
Tab.Panels = HeadlessTab.Panels;
Tab.Panel = HeadlessTab.Panel;

export default Tab;
