import classnames from 'classnames';
import { Html, Icon } from '@webfx/core-web';
import { useContributors } from '@webfx/web-hooks';

import styles from './TitleBar.module.css';

const Stat = ({ icon, stat, label }) => {
  return (
    <div className={classnames(styles.stat, 'd-flex align-items-center')}>
      <Icon className="mt-1 p-2 rounded-circle text-blue-vivid-300 font-28 bg-blue-vivid-100">
        {icon}
      </Icon>
      <div className={styles.statContent}>
        <div
          className="font-24 bold"
          data-fx-name="totalCompanyStats"
          style={{ lineHeight: '1.4em' }}
        >
          {stat}
        </div>
        <Html
          className="font-12"
          data-fx-name="companyStatsLabel"
          str={label}
          style={{ lineHeight: '1.2em' }}
        />
      </div>
    </div>
  );
};

const CompanyStats = ({ companyId }) => {
  const { data } = useContributors(companyId);
  const { companyStats, globalStats } = data || {};
  return (
    <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
      <div className={styles.statsGrid}>
        {companyStats?.contributors ? (
          <Stat
            icon="people"
            stat={companyStats?.contributors}
            label="Expert Contributors On Your Account <em>(Past 12 Months)</em>"
          />
        ) : null}
        {companyStats?.contributors_w_smes ? (
          <Stat
            icon="bar_chart"
            stat={companyStats?.contributors_w_smes}
            label="Certified Subject Matter Experts <em>(Past 12 Months)</em>"
          />
        ) : null}
        {companyStats?.industry_hours ? (
          <Stat
            icon="schedule"
            stat={Math.round(companyStats?.industry_hours).toLocaleString()}
            label="Total WebFX Hours in Your Industry"
          />
        ) : null}
        <Stat
          icon="business"
          stat={Math.round(globalStats?.companywide_hours).toLocaleString()}
          label="Company-wide Knowledge & Expertise Hours"
        />
      </div>
    </div>
  );
};

export default CompanyStats;
