/**
 * Map the leadership title to the description.
 * @param {*} title
 * @returns {string} description
 */
const mapLeadershipColors = (title) => {
  if (title?.includes('VP of Customer Success')) {
    return {
      imageColor: 'orange-03',
      backgroundColor: 'var(--orange-01)',
    };
  }
  if (
    title?.includes('Strategic Agency Manager - Google') ||
    title?.includes('Head of Client Strategy')
  ) {
    return {
      imageColor: 'purple-03',
      backgroundColor: 'var(--purple-01)',
    };
  }
  if (title?.includes('Content')) {
    return {
      imageColor: 'green-03',
      backgroundColor: '#D8F9E9',
    };
  }

  return {
    imageColor: 'blue-vivid-300',
    backgroundColor: '#DBEDFF',
  };
};

export default mapLeadershipColors;
