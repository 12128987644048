import { Dialog as HeadlessDialog } from '@headlessui/react';
import classnames from 'classnames';
import { Icon } from '@webfx/core-web';

import styles from './Dialog.module.css';

/**
 * Renders a dialog component.
 *
 * @param {object} props - The component props.
 * @param {string} props.className - The class name for the dialog component.
 * @param {ReactNode} props.children - The content to be rendered inside the dialog.
 * @param {function} props.onClose - The callback function to be called when the dialog is closed.
 * @param {boolean} props.open - Indicates whether the dialog is open or not.
 * @param {boolean} [props.showCloseBtn=true] - Indicates whether to show the close button or not. Default is true.
 * @param {object} props.passThroughProps - The remaining props to be passed through to the underlying component.
 * @returns {JSX.Element} The rendered dialog component.
 * @example
 * <Dialog open={bool} onClose={close}>
 *         <Dialog.Panel data-fx-name="manageLeadCalculationsModal">
 *           <Dialog.Title className="d-flex align-items-center font-20 mb-3">
 *             <Icon className="font-24 text-blue-200 mr-2" outlined={true}>
 *               add_box
 *             </Icon>
 *             <span className="font-18">MY TITLE</span>
 *           </Dialog.Title>
 *
 *           <div>
 *             MY CONTENT
 *           </div>
 *         </Dialog.Panel>
 *       </Dialog>
 */
function Dialog({
  className,
  children,
  onClose = () => {},
  open = true,
  showCloseBtn = true,
  ...passThroughProps
}) {
  return (
    <HeadlessDialog
      open={open}
      onClose={onClose}
      className={classnames(styles.screenCover, 'position-fixed')}
      {...passThroughProps}
    >
      <div className={classnames(styles.overlay, 'position-fixed w-100 h-100')}></div>
      <div className="position-fixed overflow-auto w-100 h-100">
        <div
          className={classnames(
            styles.contentContainer,
            'd-flex align-items-center justify-content-center p-4'
          )}
        >
          <div
            className={classnames('position-relative p-4 bg-white rounded', className)}
            data-fx-name="modal"
          >
            {showCloseBtn ? (
              <Icon
                className={classnames(
                  styles.closeBtn,
                  'position-absolute cursor-pointer font-28 text-gray-500 text-hover-gray-700'
                )}
                data-fx-name="closeButton"
                onClick={onClose}
              >
                close
              </Icon>
            ) : null}
            {children}
          </div>
        </div>
      </div>
    </HeadlessDialog>
  );
}

export default Dialog;
