import classnames from 'classnames';
import numbro from 'numbro';
import { useFlags } from '@webfx/web-hooks';
import { Image, Card } from '@webfx/core-web';
import KeyLeadership from './KeyLeadership';

import styles from './Sidebar.module.css';
import KeyLeadershipV1 from './KeyLeadershipV1';

const assetFolder = '/assets/images/contributors';

const Stat = ({ image, label, children }) => {
  const imagePath = `${assetFolder}/${image}`;
  return (
    <div className="d-flex">
      <div
        className={classnames(
          styles.smeIcon,
          'd-flex align-items-center justify-content-center mt-1'
        )}
      >
        <Image src={imagePath} srcSet={true} className="mb-1" />
      </div>
      <div className="d-flex flex-column ml-2">
        <span className="font-14 bold">{label}</span>
        <span className="font-12">{children}</span>
      </div>
    </div>
  );
};
const Sidebar = ({ data, companyId, industry }) => {
  const flags = useFlags();

  return (
    <>
      {data?.leaders && !flags.analyticsfxContributors ? (
        <KeyLeadershipV1 industryName={data?.industryName} leaders={data.leaders} />
      ) : null}

      {flags.analyticsfxContributors ? (
        <KeyLeadership companyId={companyId} industry={industry} />
      ) : null}

      <div className="mt-4">
        <Card className="p-2">
          <Card.Title className="font-20 bold">Subject Matter Expertise</Card.Title>
          <Card.Text className="font-14">
            WebFX subject matter experts complete rigorous training, practical applications, and
            strategic analysis to earn the subject matter expert designation.
          </Card.Text>
          <div className={styles.smeGrid}>
            <Stat image="map-pin.png" label="SEO">
              100+ full-time subject matter experts
            </Stat>
            <Stat image="icon-ppc.png" label="PPC">
              100+ full-time subject matter experts
            </Stat>
            <Stat image="icon-ebook.png" label="FXLearns">
              {numbro(data?.globalStats?.fx_learns_completed_resources).format({
                thousandSeparated: true,
                mantissa: 0,
              })}{' '}
              resources completed across WebFX
            </Stat>
            {data?.globalStats?.google_analytics_certified ? (
              <Stat image="google-analytics.png" label="Google Analytics Certified">
                {data.globalStats.google_analytics_certified} team members certified
              </Stat>
            ) : null}
            {data?.globalStats?.google_ads_certified ? (
              <Stat image="google-ads.png" label="Google Ads Certified">
                {data.globalStats.google_ads_certified} team members certified
              </Stat>
            ) : null}

            {flags.analyticsfxContributors && data?.globalStats?.results_enablement ? (
              <Stat image="results-enablement.png" label="Dedicated Results Enablement Team">
                {data.globalStats.results_enablement} team members certified
              </Stat>
            ) : null}
          </div>
        </Card>
      </div>

      <div
        className={classnames(styles.sidebarGraphic, 'd-flex flex-column mt-4 p-4 pb-5 text-white')}
      >
        <span className="font-24 bold">We support your entire digital marketing funnel.</span>
        <span className="mt-2 font-16 w-lg-75">
          We do that through our subject matter experts AND our proprietary technology.
        </span>
      </div>
    </>
  );
};

export default Sidebar;
