/**
 * An abstracted Dialog component wrapper around
 * https://headlessui.com/react/dialog
 */
import { Dialog as HeadlessDialog } from '@headlessui/react';
import Dialog from './Dialog';

Dialog.Panel = HeadlessDialog.Panel;
Dialog.Title = HeadlessDialog.Title;
Dialog.Description = HeadlessDialog.Description;

export default Dialog;
