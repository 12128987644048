import { useStoreState } from 'easy-peasy';

/**
 * Returns whether the user is a mobile platform
 *
 * @returns boolean
 */
export default function useIsMobile() {
  const isMobile = useStoreState((state) => state.common.isMobile);

  return Boolean(isMobile);
}
