import classNames from 'classnames';
import styles from './Screenshot.module.css';

const authKey = process.env.POI_APP_THUM_IO_AUTH_KEY;

export default function Screenshot({
  url,
  width = 200,
  crop = 640,
  className,
  ...passThroughProps
}) {
  if (!url) {
    return (
      <div className={classNames(styles.error, className)} {...passThroughProps}>
        NO THUMBNAIL FOUND
      </div>
    );
  }

  const src = `//image.thum.io/get/auth/${authKey}/width/${width}/crop/${crop}/${
    url.match(/^http/) ? url : `http://${url}`
  }`;
  return <img src={src} className={className} {...passThroughProps} />;
}
