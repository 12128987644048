import React from 'react';
import classnames from 'classnames';
import { Icon, Avatar, Svg } from '@webfx/core-web';
import { SlidePane } from '@webfx/web-ui';

import getColorByHours from '../utils/get-color-by-hours';
import fxLogo from '../assets/images/svgs/fx-logo.svg';
import ribbon from '../assets/images/svgs/medal-ribbon.svg';

import styles from './SidePanel.module.css';

const PanelHeader = ({ onClose, user }) => {
  const roundedHours = user?.expertise_hours ? Math.ceil(user?.expertise_hours) : null;
  const color = getColorByHours(roundedHours);
  const boxShadow = `0 0 0 2px var(--${color})`;

  return (
    <div className="row w-100 position-relative pt-3">
      <Icon className={classnames(styles.closeIcon, 'text-gray-600')} onClick={onClose}>
        close
      </Icon>
      <div className="col-md-8">
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <Avatar src={user?.avatar} className={styles.avatar} style={{ boxShadow }} />
          </div>
          <div>
            <div className="font-18 bold" data-fx-name="userName">
              {user?.displayName}.
            </div>
            <div className="font-12 text-gray-600">{user?.title}</div>
          </div>
        </div>
      </div>
      {user?.expertise_hours ? (
        <div className="col">
          <div className="d-flex justify-content-center flex-column h-100">
            <div>
              <div
                className={classnames(styles.hours, 'font-18 bold')}
                style={{ background: `var(--${color})` }}
              >
                {Math.ceil(user?.expertise_hours).toLocaleString()}
              </div>
            </div>
            <div
              className="font-12 text-gray-600 mt-1 bold uppercase"
              data-fx-name="expertiseHoursSidePanel"
            >
              Expertise Hours
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const SidePanel = ({ show, onClose, user }) => {
  function getUniqueListBy(arr = [], key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const [SMEs, industryHours, certifications] = React.useMemo(
    () => [
      getUniqueListBy(user?.SMEs, 'sme_name'),
      getUniqueListBy(user?.allIndustryHours, 'industry')?.sort((a, b) => b.hours - a.hours),
      getUniqueListBy(user?.certs, 'cert_title'),
    ],
    [user]
  );

  return (
    <SlidePane
      show={show}
      backdrop
      onClose={onClose}
      keyboard
      showCloseBtn={false}
      headerContent={<PanelHeader user={user} onClose={onClose} />}
      headerClass={styles.paneHeader}
    >
      <div>
        <div>
          <div className={classnames(styles.sectionTitle, 'font-14 d-flex align-items-center')}>
            <Svg
              src={fxLogo}
              className={classnames('mr-1 d-flex align-items-center', styles.smeRibbon)}
            />
            <div data-fx-name="areasOfExpertise">Areas of Expertise</div>
          </div>

          <div className={classnames('d-flex mt-2 px-2 w-full', styles.expertiseWrapper)}>
            {SMEs.map((item) => (
              <div className="d-flex flex-column align-items-center mr-2" key={item.sme_name}>
                <div className={styles.skillIcon}>
                  <img className="w-100" src={item.icon_url} />
                </div>
                <div className={classnames(styles.skillName, 'mt-3 font-12 text-center')}>
                  {item.sme_name}
                </div>
              </div>
            ))}
          </div>
        </div>

        {certifications?.length ? (
          <div className="mt-4">
            <div className={classnames(styles.sectionTitle, 'font-14 d-flex align-items-center')}>
              <Svg
                src={ribbon}
                className={classnames('mr-1 d-flex align-items-center', styles.smeRibbon)}
              />
              <div data-fx-name="certification">Certifications</div>
            </div>

            <div
              className={classnames(
                'd-flex align-items-center mt-2 px-2 w-full',
                styles.expertiseWrapper
              )}
            >
              {certifications.map((item) => (
                <div className="d-flex flex-column align-items-center mr-3" key={item.icon_url}>
                  <img className={classnames(styles.certIcon)} src={item.icon_url} />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div className="mt-4">
          <div className={classnames(styles.sectionTitle, 'font-14 d-flex align-items-center')}>
            <Svg
              src={fxLogo}
              className={classnames('mr-1 d-flex align-items-center', styles.smeRibbon)}
            />
            <div data-fx-name="industryExpertise">Industry Expertise</div>
          </div>
          <div className={classnames(styles.industrySection, 'mt-3')}>
            <div className={classnames(styles.columnHeader, 'uppercase')}>Industry</div>
            <div className={classnames(styles.columnHeader, 'uppercase')}>Hours</div>
            {industryHours.map((item) => (
              <React.Fragment key={item.industry}>
                <div className={classnames(styles.industry, 'd-flex align-items-center')}>
                  {item.icon ? (
                    <Icon className="font-24 text-blue-vivid-300 mr-3">{item.icon}</Icon>
                  ) : null}
                  <div>{item.industry}</div>
                </div>
                <div className="font-14 bold">{Math.ceil(item.hours).toLocaleString()}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </SlidePane>
  );
};

export default SidePanel;
