import classnames from 'classnames';
import styles from './InnerScroll.module.css';

/**
 * Renders a scrollable container with inner content.
 * @param {object} props - The component props.
 * @param {ReactNode} props.children - The inner content of the scrollable container.
 * @param {string} props.className - The class name for the outer container.
 * @param {string} props.innerClassName - The class name for the inner container.
 * @param {object} theirProps - The remaining props to be spread on the outer container.
 * @returns {ReactNode} The rendered scrollable container.
 */
export function InnerScroll({ children, className, innerClassName, ...theirProps }) {
  return (
    <div className={classnames(styles.container, className)} {...theirProps}>
      <div className={classnames(innerClassName)}>{children}</div>
    </div>
  );
}
