import { useState, useMemo } from 'react';
import classnames from 'classnames';
import { Image, Icon } from '@webfx/core-web';
import { useActiveSite, useAuth } from '@webfx/web-hooks';
import ShareReportModal from '@webfx/marketingcloud-web/src/ui/pages/Analytics/ShareReportModal/ShareReportModal';
import { generateContributorsShareLink } from '@webfx/marketingcloud-web/src/ui/pages/Analytics/utils';
import useCompanyIndustry from '../hooks/useCompanyIndustry';

import BottomHeader from './BottomHeader';

import styles from './Header.module.css';

const Header = ({ companyId, isEmbed }) => {
  const { siteId } = useActiveSite();
  const { data: industry } = useCompanyIndustry(companyId);
  const { isGuest } = useAuth();
  const [shareLink, setShareLink] = useState(null);
  const [industryLogo, setIndustryLogo] = useState(null);

  const APP_URL = process.env.FX_APP_URL ? process.env.FX_APP_URL : process.env.POI_APP_URL;

  const onShare = async () => {
    const url = await generateContributorsShareLink(siteId);
    setShareLink(url);
  };

  // This is a fix for the industry logo when using an non-public mcfx upload on local
  useMemo(() => {
    if (
      [
        'https://media.webfx.com/avatars/null',
        'https://app.webfx.com/avatars/',
        'https://app.webfx.com/uploads/avatars/null',
        'https',
      ].includes(industry?.industryLogo)
    ) {
      return;
    }
    setIndustryLogo(
      industry?.industryLogo.replace('https://app.webfx.com/uploads/', `${APP_URL}/uploads/`)
    );
  }, [industry, APP_URL]);

  return (
    <div className={classnames('d-flex flex-column w-100', styles.header)}>
      {/* Industry Background  */}
      <div
        className={classnames([
          'd-flex justify-content-between bg-blue-400',
          styles.topHeader,
          { [styles.topHeaderRound]: !isEmbed },
        ])}
      >
        {/* Industry Name */}
        {industry?.industryImage && <Image src={industry?.industryImage} />}
        <div className={classnames(styles.overlayContent, 'm-3')}>
          <div className="d-flex align-items-center">
            {industryLogo && <img src={industryLogo} className="mr-3" />}
            {industry?.industry && (
              <p className="m-0 text-white bold">{`WebFX ${industry?.industry} Industry Center of Excellence`}</p>
            )}
          </div>
        </div>

        {/* Share Link */}
        {!isGuest && !isEmbed && (
          <button
            data-fx-name="label"
            onClick={() => {
              onShare();
            }}
            className={classnames(
              styles.shareButton,
              'btn text-primary text-decoration-none bg-blue-vivid-100 d-flex align-items-center m-3'
            )}
          >
            <p className="my-0 mr-2 font-14 semibold text-decoration-none">Share Link</p>
            <Icon className="mb-1 bold">insert_link</Icon>
          </button>
        )}
      </div>
      <BottomHeader companyId={companyId} />

      <ShareReportModal
        show={!!shareLink}
        link={shareLink}
        onClose={() => {
          setShareLink(null);
        }}
      />
    </div>
  );
};

export default Header;
