import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from './useLocalStorage';

const useUserUUID = () => {
  const [userUUID, setUserUUID] = useLocalStorage('userUUID');

  if (!userUUID) {
    setUserUUID(uuidv4());
  }

  return userUUID;
};

export default useUserUUID;
