import { Badge } from '@webfx/core-web';
import { useChannel } from '@webfx/web-hooks';
import classnames from 'classnames';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ChannelIcon from '../ChannelIcon';

function MroiChannel({ type = 'icon', ...passThroughProps }) {
  return type === 'icon' ? <MroiIcon {...passThroughProps} /> : <MroiLabel {...passThroughProps} />;
}

function MroiIcon({ channel, size = 22, className, useShortLabel = false }) {
  const mappedChannel = useChannel(channel);

  if (!mappedChannel) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip>
          {useShortLabel && mappedChannel.labelShort
            ? mappedChannel.labelShort
            : mappedChannel.label}
        </Tooltip>
      }
    >
      <span>
        <ChannelIcon
          icon={mappedChannel.icon}
          className={className}
          style={{ width: size, height: size }}
        />
      </span>
    </OverlayTrigger>
  );
}

function MroiLabel({
  channel,
  size = 22,
  className,
  variant,
  labelClassName,
  useShortLabel = false,
  ...passThroughProps
}) {
  const mappedChannel = useChannel(channel);

  if (!mappedChannel) {
    return null;
  }

  return (
    <Badge
      pill
      variant={variant}
      className={classnames([
        'badge bg-blue-100 pt-1 px-2 d-flex align-items-center font-weight-normal font-14',
        className,
      ])}
      data-fx-name={passThroughProps['data-fx-name']}
    >
      <ChannelIcon
        icon={mappedChannel.icon}
        className={classnames(['mr-1', className])}
        style={{ width: size, height: size }}
      />
      <span className={classnames(['', labelClassName])} data-fx-name="label">
        {useShortLabel && mappedChannel.labelShort ? mappedChannel.labelShort : mappedChannel.label}
      </span>
    </Badge>
  );
}

MroiChannel.Icon = MroiIcon;
MroiChannel.Label = MroiLabel;

export default MroiChannel;
