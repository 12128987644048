const getColorByHours = (hours) => {
  if (hours) {
    if (hours > 10000) {
      return 'blue-vivid-300';
    }
    if (hours > 5000) {
      return 'green-04';
    }
    if (hours > 1000) {
      return 'orange-04';
    }
    if (hours > 0) {
      return 'yellow-05';
    }
  }
  return 'gray-550';
};

export default getColorByHours;
