import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Custom hook for fetching channels for a site.
 *
 * @param {Object} query - The query parameters for fetching channels.
 * @param {Object} reactQueryOpts - The options for React Query.
 * @returns {Object} - The result of the query.
 */
export function useChannels(query = {}, reactQueryOpts = {}) {
  const { siteId } = useActiveSite();

  return api.useQuery(['marketing/cpl/channels', { siteId, ...query }], {
    staleTime: 1000 * 60 * 60, // 1 hour
    cacheTime: 1000 * 60 * 20, // 20 hours
    ...reactQueryOpts,
  });
}

export function useChannel(channelId, reactQueryOpts = {}) {
  const { data: channel } = useChannels(
    {},
    {
      select: (data) => (data ?? [])?.find((channel) => channel.channelId === channelId),
      ...reactQueryOpts,
    }
  );

  return channel;
}
